.article-list {
    @media #{$media-xs} {
        margin-bottom: 25px;
    }

    h3 {
        font-size: 21px;
        color: $blue-light;

        @include n-theme() {
            color: theme(color-primary);
        }
    }

    p {
        line-height: 20px;
        padding-bottom: 5px;
    }

    ul {
        height: 100%;
        clear: both;
        margin-top: 5px;

        @media #{$media-sm-up} {
            column-count: 2;
            column-gap: 30px;
        }

        li {
            border-bottom: 1px dashed $gray-lighter;
            border-top: 1px dashed $gray-lighter;
            display: block;
            height: 100%;
            break-inside: avoid-column;

            &:not(:first-child) {
                margin-top: -1px;
            }

            a {
                padding: 13px 0;
                display: inline-block;
                font-weight: 700;
                font-size: 13px;

                &:hover {
                    background: #f5f5f5;
                }
            }
        }
    }

    .btn-link {
        i {
            display: inline-block;
            font-weight: bold;
            padding-bottom: 4px;
        }
    }

    &__main {
        display: table;

        > div,
        a {
            display: table-cell;
            vertical-align: top;
        }

        div {
            padding-right: 20px;
        }

        h3 {
            color: $blue;

            @include n-theme() {
                color: theme(color-primary);
            }
        }

        .badge-icon {
            width: 75px;
            height: 75px;

            i {
                font-size: 45px;
                line-height: 71px;
            }
        }
    }

    &--img {
        h3 {
            color: $blue;

            @include n-theme() {
                color: theme(color-primary);
            }
        }

        img {
            padding: 10px 0 20px;
            width: 100%;
            height: auto;
        }

        h4 {
            margin-bottom: 5px;
        }

        ul {
            column-count: 1;
            margin-bottom: 13px;

            @media #{$media-sm} {
                margin-bottom: 19px;
            }
        }
    }
}
