/* TOOLBAR
-------------------------------------------------------------- */
.toolbar {
    label,
    ul {
        display: inline-block;
        margin: 0;
    }

    label {
        font-weight: $font-weight-bold;
        vertical-align: middle;
    }

    .select-wrapper {
        display: inline-block;
        margin-left: 15px;
        width: 200px;
        vertical-align: middle;

        @media #{$media-xs} {
            width: 185px;
        }
    }

    .custom-select {
        display: inline-block;
    }

    &__bottom {
        .toolbar-pagination {
            @media #{$media-xs} {
                width: 100%;
            }
        }

        .toolbar-pager {
            @media #{$media-to-sm} {
                display: none;
            }

            @media #{$media-xs} {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .pagination {
            width: 100%;
            text-align: center;
        }
    }
}

/* SORT
-------------------------------------------------------------- */
.toolbar-sort {
    /* direction arrows */
    &__dir {
        display: inline-block;
        width: 20px;
        margin-left: 15px;
        vertical-align: middle;

        i {
            font-size: 8px;
        }

        a {
            display: block;
            height: 10px;
            line-height: 10px;

            &.active {
                i {
                    color: palette(red);
                }
            }

            & + a {
                margin-top: 5px;
            }
        }
    }
}

/* PAGER
-------------------------------------------------------------- */
.toolbar-pager {
    line-height: 50px;

    @media #{$media-xs} {
        margin-top: 20px;
        padding-left: 0px;
    }

    @media #{$media-sm} {
        float: right;
        padding-left: -15px;
    }

    @media #{$media-md} {
        padding-left: 0;
        padding-right: 0;
    }

    .limiter {
        ul.list-inline {
            li {
                padding-right: 0;
                padding-left: 7px;
                font-family: $font-family-secondary;

                &:not(:last-of-type):after {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 20px;
                    margin-left: 7px;
                    background: palette(gray, lighter);
                    vertical-align: middle;
                }
            }
        }

        .select-wrapper {
            width: 91px;

            .select-custom {
                padding-left: 20px;
                font-style: normal;
            }
        }
    }
}

/* PAGINATION
-------------------------------------------------------------- */
// only cms pagination
.toolbar-pagination {
    strong,
    input,
    form {
        display: inline-block;
    }

    strong {
        margin-right: 10px;
    }

    input {
        width: 50px;
        margin-right: 8px;
        text-align: center;
    }

    input,
    span {
        font-size: 16px;
        font-family: $font-family-secondary;
        font-style: normal;
    }

    .icon-arrow-left {
        margin-right: 25px;

        @media #{$media-sm} {
            margin-right: 65px;
            font-size: 36px;
        }

        @media #{$media-xs} {
            margin-right: 70px;
            font-size: 36px;
        }
    }

    .icon-arrow-right {
        margin-left: 25px;

        @media #{$media-xs} {
            margin-left: 70px;
        }

        @media #{$media-sm} {
            margin-left: 65px;
        }

        @media #{$media-to-sm} {
            font-size: 36px;
        }
    }
}
