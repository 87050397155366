.footer {
    content-visibility: auto;
    contain-intrinsic-size: 0 515px;
}

.cms-index-index {
    .product-list {
        &:nth-child(n + 3) {
            content-visibility: auto;
            contain-intrinsic-size: 0 515px;
        }
    }
}

.catalog-category-view {
    .category-list {
        li {
            @media #{$media-xs} {
                &:nth-child(n + 3) {
                    content-visibility: auto;
                    contain-intrinsic-size: 0 384px;
                }
            }

            &:nth-child(n + 7) {
                content-visibility: auto;
                contain-intrinsic-size: 292px 384px;
            }
        }
    }

    .product-slider {
        // @todo check if glider.js can handle this css property
        // issue on android mobile #SD402778
        // content-visibility: auto;
        // contain-intrinsic-size: 0 515px;
    }

    .category-description-section {
        content-visibility: auto;
        contain-intrinsic-size: 0 515px;
    }
}

.catalog-product-view {
    .product-collection {
        content-visibility: auto;
        contain-intrinsic-size: 0 300px;
    }

    .product-reviews {
        content-visibility: auto;
        contain-intrinsic-size: 0 580px;

        @media #{$media-xs} {
            contain-intrinsic-size: 0 900px;
        }
    }
}

.cms-regulamin,
.cms-polityka-prywatnosci {
    ol {
        &:nth-of-type(n + 3) {
            content-visibility: auto;
        }
    }

    p {
        &:nth-of-type(n + 5) {
            content-visibility: auto;
        }
    }

    .Tabela-Siatka1 {
        content-visibility: auto;
    }
}

.cms-pomoc {
    h2 {
        &:nth-of-type(n + 3) {
            content-visibility: auto;
        }
    }

    .faq-items {
        &:nth-of-type(n + 3) {
            content-visibility: auto;
        }
    }
}

// Articles
.cms-content {
    @media #{$media-xs} {
        .aside--right {
            content-visibility: auto;
        }
        .main-color-boxes {
            .row {
                &:nth-of-type(n + 2) {
                    content-visibility: auto;
                }
            }
        }
    }

    .homepage__our-experts {
        content-visibility: auto;
        contain-intrinsic-size: 0 436px;
    }

    .homepage__see-also {
        content-visibility: auto;
        contain-intrinsic-size: 0 799px;
    }

    .article__footer {
        content-visibility: auto;
        contain-intrinsic-size: 0 348px;
    }

    .article-list-wrapper {
        content-visibility: auto;
    }
}
