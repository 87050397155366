/* CORE TBLE
-------------------------------------------------------------- */
table {
    width: 100%;
    max-width: 100%;
    border: 0;
}

/* CONTENT TABLE
-------------------------------------------------------------- */
.table-content {
    margin-bottom: 25px;
    border: 1px solid $table-border-color;
    background-color: $table-bg;
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);

    @media #{$media-sm-up} {
        table-layout: fixed;
    }

    /* rows */
    tr {
        border-bottom: 1px solid $table-border-color;

        &:nth-of-type(odd) {
            background-color: $table-bg-accent;
        }
    }

    /* cells */
    th,
    td {
        padding: 7px 15px;
        border: 0;
        color: $text-color;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;

        @media #{$media-xs} {
            padding: 5px;
            word-break: break-word;
        }

        &:first-of-type {
            padding-left: 20px;

            @media #{$media-xs} {
                padding-left: 5px;
            }
        }
    }

    td {
        font-size: 13px;
        vertical-align: top;

        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.table--hover {
    tbody {
        tr {
            &:hover {
                background-color: $gray-lighter2;
            }
        }
    }
}

/* RESPONSIVE TABLE
-------------------------------------------------------------- */
.table-responsive {
    @media #{$media-xs} {
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        margin-bottom: 25px;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid $table-border-color;

        table {
            margin-bottom: 0;
            border-width: 0;
            box-shadow: none;

            > thead,
            > tbody,
            > tfoot {
                > tr {
                    &:last-child {
                        border-bottom-width: 0;
                    }

                    > th,
                    > td {
                        min-width: 95px;
                    }
                }
            }
        }
    }
}
