/* LINK ARROW
-------------------------------------------------------------- */
.link-arrow {
    &__icon {
        display: inline-block;
        margin-bottom: 0.4em;
        margin-top: 1px;
        font-size: 0.533em;
        vertical-align: text-top;
        margin-left: 4px;

        .icon {
            margin-left: -2px;
        }
    }
}
