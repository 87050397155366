/* ANIMATE FOR LAZY LOADING PLUGIN
-------------------------------------------------------------- */
.b-lazy,
// .vb-lazy separated selector for
.vb-lazy {
    opacity: 0;
    @include n-transition(opacity 0.4s);
}

.b-loaded {
    opacity: 1;
}
