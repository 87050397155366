/* COLOR BOX SET HEIGHT
// aside variables are only when image is on the left side
-------------------------------------------------------------- */
@mixin color-box-height-sm-up(
    $height: 123px,
    $leftImage: 200%,
    $imageHeight: 49%,
    $titleAsideSize: '',
    $titleSize: '',
    $titleAsideLine: '',
    $titleAsideMargin: '',
    $paddingAside: '',
    $padding: ''
) {
    @media #{$media-sm-up} {
        height: $height;
        max-height: $height;

        &.color-box--img-left-sm-up {
            .color-box__img {
                flex: $leftImage;
            }

            .color-box__desc {
                flex: 100%;

                @if $paddingAside != '' {
                    padding: $paddingAside;
                }

                .title {
                    @if $titleAsideSize != '' {
                        font-size: $titleAsideSize;
                    }

                    @if $titleAsideLine != '' {
                        line-height: $titleAsideLine;
                    }

                    @if $titleAsideMargin != '' {
                        margin: $titleAsideMargin;
                    }
                }
            }
        }

        &:not(.color-box--img-left-sm-up) {
            .color-box__img {
                height: $imageHeight;
            }

            .color-box__desc {
                height: 100% - $imageHeight;

                @if $padding != '' {
                    padding: $padding;
                }

                .label-tag {
                    margin-bottom: 4px;
                }
            }
        }
    }
}

@mixin color-box-height-xs(
    $height: 123px,
    $heightAside: 123px,
    $leftImage: 200%,
    $imageHeight: 49%,
    $labelMargin: 4px,
    $titleMargin: '',
    $titleAsideSize: '',
    $titleSize: '',
    $titleAsideLine: '',
    $titleAsideMargin: '',
    $paddingAside: '',
    $padding: ''
) {
    @media #{$media-xs} {
        height: $height;
        max-height: $height;

        &.color-box--img-left-xs {
            height: $heightAside;

            .color-box__img {
                flex: $leftImage;
            }

            .color-box__desc {
                flex: 100%;

                @if $paddingAside != '' {
                    padding: $paddingAside;
                }

                .title {
                    @if $titleAsideSize != '' {
                        font-size: $titleAsideSize;
                    }

                    @if $titleAsideLine != '' {
                        line-height: $titleAsideLine;
                    }

                    @if $titleAsideMargin != '' {
                        margin: $titleAsideMargin;
                    }
                }
            }
        }

        &:not(.color-box--img-left-xs) {
            .color-box__img {
                height: $imageHeight;
            }

            .color-box__desc {
                height: 100% - $imageHeight;

                @if $padding != '' {
                    padding: $padding;
                }

                .title {
                    @if $titleSize != '' {
                        font-size: $titleSize;
                    }

                    @if $titleMargin != '' {
                        margin-bottom: $titleMargin;
                    }
                }

                .label-tag {
                    margin-bottom: $labelMargin;
                }
            }
        }
    }
}

/* COLOR BOX
-------------------------------------------------------------- */
.color-box {
    border-bottom: 2px solid;
    box-shadow: 0 0 15px rgba(25, 25, 25, 0.1);
    margin-bottom: 25px;

    &:first-child {
        clear: both;
    }

    /* HOVER
    -------------------------------------------------------------- */
    &:hover {
        .color-box__desc {
            background-image: linear-gradient(
                    40deg,
                    rgba(58, 155, 220, 0.15) 0%,
                    rgba(255, 255, 255, 0.15) 60%,
                    rgba(255, 255, 255, 0.15) 100%
            )
        }
    }

    /* COLOR BOX WITH IMAGE ON LEFT SIDE
    -------------------------------------------------------------- */
    &--img-left-sm-up {
        .link {
            @media #{$media-sm-up} {
                display: flex;
                flex-direction: row;
                height: 100%;
            }
        }

        .color-box__img {
            @media #{$media-sm-up} {
                flex: 120%;
                height: auto;
                margin-bottom: -2px;
            }
        }
    }

    &--img-left-xs {
        .link {
            @media #{$media-xs} {
                display: flex;
                flex-direction: row;
                height: 100%;
            }
        }

        .color-box__img {
            @media #{$media-xs} {
                flex: 120%;
                height: auto;
                margin-bottom: -2px;
            }
        }
    }

    /* COLOR BOX WITH DIFFERENT SIZES OF BOX (HEIGHT)
    -------------------------------------------------------------- */

    &--very-big {
        @include color-box-height-sm-up(
            $height: 536px,
            $leftImage: 170%,
            $imageHeight: 37%,
            $titleAsideSize: 26px,
            $titleSize: 26px,
            $titleAsideLine: 29px,
            $titleAsideMargin: 0 0 22px 0,
            $paddingAside: 57px 45px 57px 50px,
            $padding: 46px 42px 10px
        );

        &:not(.color-box--img-left-sm-up) {
            .title {
                font-size: 26px;
                line-height: 29px;
                margin: 0 0 22px 0;
            }
        }
    }

    &--big {
        @include color-box-height-sm-up(
            $height: 322px,
            $leftImage: 170%,
            $titleAsideSize: 26px,
            $titleAsideLine: 29px,
            $titleAsideMargin: 0 0 22px 0,
            $paddingAside: 57px 45px 57px 50px
        );
        @include color-box-height-xs(
            $height: 521px,
            $imageHeight: 42%,
            $titleSize: 26px,
            $padding: 48px 34px 10px,
            $labelMargin: 9px,
            $titleMargin: 25px
        );

        .color-box__desc {
            .title {
                @media #{$media-xs} {
                    line-height: 1.25;
                }
            }
        }
    }

    &--medium {
        @include color-box-height-sm-up(
            $height: 253px,
            $leftImage: 185%,
            $imageHeight: 49%,
            $titleAsideSize: 21px,
            $titleAsideLine: 24px,
            $paddingAside: 39px 30px,
            $padding: 26px 15px 10px 22px
        );
        @include color-box-height-xs(
            $heightAside: 123px,
            $height: 521px,
            $leftImage: 116%,
            $imageHeight: 42%,
            $paddingAside: 23px 17px,
            $padding: 47px 25px 10px 34px,
            $titleSize: 21px,
            $titleMargin: 23px
        );

        .color-box__desc {
            .title {
                @media #{$media-xs} {
                    line-height: 1.25;
                }
            }
        }
    }

    &--small {
        @include color-box-height-sm-up(
            $height: 123px,
            $leftImage: 98%,
            $paddingAside: 30px 10px 30px 25px
        );
        @include color-box-height-xs(
            $height: 123px,
            $leftImage: 114%,
            $paddingAside: 23px 10px 30px 19px
        );
    }

    /* IMAGE - IS A BACKGROUND IMAGE
    -------------------------------------------------------------- */
    &__img {
        position: relative;
        width: 100%;
        height: 175px;
        overflow: hidden;

        @media #{$media-sm} {
            height: 250px;
        }

        @media #{$media-xs} {
            height: 200px;
        }

        .img {
            position: absolute;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: none;
            object-fit: cover;
            object-position: center;
        }
    }

    /* DESCRIPTION
    // .label-tag   label with name of category
    // .title       title
    // .text        text/description
    // i            icon ">" after text
    -------------------------------------------------------------- */
    &__desc {
        position: relative;
        padding: 20px 25px 10px;
        overflow: hidden;

        &:before {
            @include n-logotype-bg($size: 100%);
            content: '';
            background-size: 120px;
            background-position: right -58px top -58px;
            right: 0;
            top: 0;
            pointer-events: none;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background-color: #fff;
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                rgb(255, 255, 255) 66%,
                rgb(255, 255, 255) 100%
            );
        }

        .label-tag {
            font-size: 11px;
            margin-bottom: 1px;
        }

        .title {
            font-size: 15px;
            font-weight: 600;
            line-height: 19px;
        }

        .text {
            font-size: 13px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        i {
            font-size: 11px;
            margin-left: 4px;
        }
    }

    /* COLOR BOX WITHOUT BACKGROUND IN RIGHT TOP CORNER
    -------------------------------------------------------------- */
    &--desc-no-bg {
        .color-box__desc {
            &:before {
                display: none;
            }
        }
    }

    /* FLEXBOX VERSION
    -------------------------------------------------------------- */
    &--flex {
        height: 100%;
        display: flex;
        flex-direction: column;

        .color-box__desc {
            flex: 1;
        }
    }

    /* LINK WRAPPER
    -------------------------------------------------------------- */
    .link {
        width: 100%;
    }
}
