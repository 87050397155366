/* GLOSSARY MODULE
-------------------------------------------------------------- */
.glossary {
    position: relative;
    overflow: hidden;
    float: none;

    .newsletter-box {
        margin-bottom: 50px;

        @media #{$media-to-sm} {
            margin: 50px 0;
        }
    }

    /* DETAILS
    -------------------------------------------------------------- */
    &--details {
        .glossary-content {
            padding-bottom: 50px;
            margin-bottom: 50px;
            border-bottom: 1px dashed $gray;
        }

        .glossary-desc {
            margin-bottom: 30px;
        }
    }

    /* HEADER
    -------------------------------------------------------------- */
    &-header {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    /* TITLE
    -------------------------------------------------------------- */
    &-title {
        margin-bottom: 40px;

        h1 {
            margin: 0;
            vertical-align: middle;
            font-weight: $headings-font-weight;
        }
    }

    /* CONTENT
    -------------------------------------------------------------- */
    &-content {
        // reset styles
        &.std {
            .glossary-content__substance {
                color: inherit;
                text-decoration: none;
            }
        }

        &__substance {
            border-bottom: 1px solid $gray;
            margin-left: 5px;
            padding-bottom: 2px;

            &:first-of-type {
                margin-left: 0;
            }
        }

        &__subjects {
            display: block;
            margin-bottom: 5px;
        }
    }

    /* DESCRIPTION
    -------------------------------------------------------------- */
    &-desc {
        margin-bottom: 50px;
    }

    /* SEARCH
    -------------------------------------------------------------- */
    &-search {
        width: 100%;

        input {
            background: transparent;
            border-color: #c8c8c8;

            @include placeholder($gray-dark);
        }

        button {
            min-width: 145px;
        }
    }

    /* CHOOSE INDEX
    -------------------------------------------------------------- */
    &-substance {
        a {
            &:hover,
            &:active,
            &.active {
                color: palette(blue, light);

                @include n-theme() {
                    color: theme(color-primary);
                }
            }
        }
    }

    /* ALPHABETICAL INDEX
    -------------------------------------------------------------- */
    &-index {
        margin-top: 40px;
        margin-bottom: 50px;
        font-size: 21px;
        text-transform: uppercase;

        @media #{$media-to-sm} {
            margin: 40px 0 30px 0;
        }

        ul {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        li {
            display: table-cell;
            text-align: center;
            border-right: 1px solid #c8c8c8;
            line-height: 22px;
            width: 40px;

            @media #{$media-to-md} {
                width: 30px;
            }

            @media #{$media-to-sm} {
                display: inline-block;
                margin: 10px 0;
            }
        }

        a {
            position: relative;
            display: inline-block;
            color: #646464;

            &.active {
                &:after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    background: palette(blue, light);
                    left: -2px;
                    right: -2px;
                    bottom: -6px;

                    @include n-theme() {
                        background-color: theme(color-primary);
                    }
                }
            }
        }
    }

    /* SUBSTANCES LIST
    -------------------------------------------------------------- */
    &-results {
        margin: 50px 0 40px 0;

        ul {
            font-size: 0; // avoid white-space between inline-blocks

            li {
                display: inline-block;
                margin-bottom: 20px;
                width: 25%;
                font-size: 17px;
                vertical-align: top;

                @media #{$media-to-sm} {
                    width: 50%;
                }

                @media #{$media-xs} {
                    display: block;
                }

                &.invisible {
                    width: 0;
                    height: 0;
                    overflow: hidden;
                    margin: 0;
                }
            }

            a {
                color: #646464;
            }
        }
    }

    /* SUBSTANCE DETAIL
    -------------------------------------------------------------- */
    &-details {
        display: none;
        padding-top: 35px;
        border-top: 1px dashed palette(gray, lighter);

        &__name {
            margin-bottom: 25px;
            font-size: 17px;
            font-weight: $headings-font-weight;
        }

        &__desc {
            p {
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    /* SEARCH LIST
    -------------------------------------------------------------- */
    &-list {
        margin-bottom: 45px;

        &__results {
            margin-bottom: 35px;

            mark {
                font-weight: $font-weight-bold;
                color: palette(blue, light);
                background: transparent;

                @include n-theme() {
                    color: theme(color-primary);
                }
            }
        }

        &__item {
            display: block;
            padding: 30px 0;
            border-bottom: 1px solid #ededed;
        }

        &__title {
            font-size: 21px;
        }

        &__desc {
            font-style: italic;
        }

        .search-page__info {
            margin-top: 60px;
        }

        .toolbar-pagination {
            display: table;
            margin: 0 auto;
        }
    }

    .product-collection__title {
        span {
            color: $blue;
        }
    }

    .article__see-also {
        margin-top: 45px;
    }
}

body.category-substancje-czynne,
body.catalog-category-view {
    .category-header {
        #div-gpt-ad-LEADBOARD-0 {
            margin-bottom: 30px;
        }
    }
}

body.catalog-category-view {
    .category-header {
        .glossary-desc {
            margin-bottom: 0;
        }
    }
}
