/* TAB NAVIGATION
-------------------------------------------------------------- */
.tab-nav {
    display: table;
    width: 100%;

    > li {
        display: table-cell;

        &:hover,
        &:focus {
            .tab-nav__item {
                background: palette(gray, light-hover);
                color: inherit;
            }
        }

        /* active state */
        &.is-active,
        &.active {
            .tab-nav__item {
                color: #fff;
                background: $brand-primary;

                @include n-theme() {
                    background-color: theme(background-primary);
                }

                &:after {
                    content: '';
                }

                a {
                    color: #fff;
                }
            }
        }

        @media #{$media-xs} {
            display: block;
        }
    }

    &__item {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 15px 20px;
        background: $gray-light;
        font-weight: $font-weight-bold;
        color: $text-color;
        @include n-transition();

        @media #{$media-md} {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media #{$media-sm-up} {
            text-align: center;
        }

        &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6.5px 7px 6.5px;
            border-color: transparent transparent #ffffff transparent;
        }

        .icon-special {
            margin-right: 10px;
            font-size: 22px;
            color: palette(red);
        }
    }

    &--inline {
        li {
            @media #{$media-sm-up} {
                display: inline-block;
            }
        }
    }
}

/* TAB CONTENT
-------------------------------------------------------------- */
.tab-content {
    position: relative;
    z-index: 1;

    @media #{$media-sm-up} {
        box-shadow: 0 -5px 30px rgba(25, 25, 25, 0.1);
    }

    @media #{$media-sm} {
        //box-shadow: 0 2px 15px rgba(25,25,25,0.06)
    }

    &__item {
        display: none;
        padding: 50px 95px;

        &::before,
        &::after {
            @include n-logotype-bg(460px);
            pointer-events: none;

            @media #{$media-sm-up} {
                content: '';
            }
        }

        &::before {
            left: 0;
            bottom: 0;
            background-position: -230px 260px;
        }

        &::after {
            right: 0;
            top: 0;
            background-position: 234px -263px;
        }

        @media #{$media-md} {
            padding: 40px 35px;
        }

        @media #{$media-sm} {
            //padding: 35px 25px;
        }

        @media #{$media-xs} {
            padding: 35px 15px;
        }

        p {
            font-size: 16px;
        }

        &.is-active {
            display: block;
        }
    }
}

/* TAB RESPONSIVE
-------------------------------------------------------------- */
.tab-responsive {
    &__title {
        text-align: left;
        padding: 0;
        border-bottom: 1px solid $nav-tabs-border-color;

        @media #{$media-xs} {
            display: block;
        }

        &.is-active {
            background: $brand-primary;

            @include n-theme() {
                background: theme(color-primary);
            }

            a {
                color: #fff;
            }

            .arrow {
                @include rotate(180deg);
            }
        }

        a {
            display: block;
            width: 100%;
            padding: 15px 20px;
        }

        .arrow {
            @include n-abs(vertical, $height: 12px);
            right: 20px;
            font-size: 12px;
        }
    }

    .tab-nav {
        @media #{$media-xs} {
            display: none;
        }
    }
}

.c-tabs {

    @media #{$media-to-sm} {
       position: relative;
    }

    @media #{$media-xs} {
       padding-right: 0;
       margin-top: 20px;
    }

    &:after {
        @media #{$media-to-sm} {
            content: '';
            display: block;
            width: 50px;
            height: 47px;
            position: absolute;
            right: 0;
            top: -7px;
            background: linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0.17) 150.67%, rgba(255, 255, 255, 0.17) 160.67%, rgba(255, 255, 255, 0.17) 178%);
        }
    }

    &__list {
        display: flex;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        @media #{$media-to-md} {
            overflow-x: scroll;
        }

        @media #{$media-to-sm} {
            position: relative;
        }
    }

    &__header {
        margin-right: 45px;
        cursor: pointer;
        padding: 10px 15px 9px;
        min-width: 130px;
        font-weight: $font-weight-medium;
        flex-shrink: 0;


        @media #{$media-to-sm} {
            margin-right: 25px;
        }

        &.is-active {
            background-color: rgba(242, 242, 242, 0.22);
            font-weight: $font-weight-bold;
            position: relative;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            &:after {
                content: '';
                border-bottom: 3px solid $sherpa-blue;
                width: 100px;
                display: block;
                position: absolute;
                bottom: 0;
            }
        }

        &:last-of-type {
            @media #{$media-to-sm} {
                margin-right: 45px;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 25px;
            line-height: 1.1;

            @media #{$media-xs} {
                font-size: 20px;
            }
        }
    }

    &__content {
        background-color: rgba(242, 242, 242, 0.22);
        border-top-left-radius: 0;
        display: block;
        visibility: hidden;
        height: 0;
        padding: 0;
        margin: 0;
        padding: 0 110px;

        @media #{$media-to-md} {
            padding: 0 40px;
        }

        @media #{$media-xs} {
            padding: 0 20px;
        }

        &.is-active {
            z-index: 1;
            visibility: visible;
            height: auto;
            padding: 40px 110px 40px;
            margin-bottom: 20px;

            @media #{$media-to-md} {
                padding: 20px 40px;
            }

            @media #{$media-xs} {
                padding: 20px;
            }

            .c-products-slider__btn {
                display: block;

                @media #{$media-xs} {
                    display: none;
                }
            }
        }

        .c-products-slider__btn {
            display: none;
        }
    }

    &__item {
        visibility: hidden;
        z-index: -999;

        &.is-active {
            visibility: visible;
            z-index: 1;
        }
    }

    .product-label {
        height: auto;
    }

}
