img {
    @include img-responsive;
}

blockquote {
    padding: 0;
    margin: 0;
    border: 0;
}

.page-popup {
    padding: $grid-gutter-width;
}

.input-group-addon label {
    margin-bottom: 0;
    font-weight: normal;
}

// Fix Bootstrap's dumb behaviour
.modal-open {
    // Bootstrap is stupid, adds a margin
    // because some browsers have a scrollbar.
    body,
    .navbar-fixed-top,
    .navbar-fixed-bottom {
        margin-right: 0;
    }

    // fix bootstrap bug - prevent additional padding right
    .modal.in {
        padding-right: 0 !important;
    }
}

// Disable zendesk button
.zEWidget-launcher {
    display: none !important;
}

.abs-center {
    @include n-abs(all);
}
