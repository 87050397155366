/* CIRCLE SPINNER
 * https://github.com/lukehaas/css-loaders
-------------------------------------------------------------- */
$foreground: #fff;

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba($foreground, 0.2);
    border-right: 1.1em solid rgba($foreground, 0.2);
    border-bottom: 1.1em solid rgba($foreground, 0.2);
    border-left: 1.1em solid rgba($foreground, 1);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@mixin load8-frames() {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes load8 {
    @include load8-frames;
}

@keyframes load8 {
    @include load8-frames;
}

/* DOTS SPINNER
 * https://github.com/tobiasahlin/SpinKit
-------------------------------------------------------------- */
$spinkit-spinner-margin: auto !default;
$spinkit-size: 80px !default;
$spinkit-spinner-color: palette(blue) !default;

/*
 *  Usage:
 *
      <div class="loader-dots">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
      </div>
 *
 */

.loader-dots {
    $animationDuration: 1.4s;
    $delayRange: 0.32s;
    margin: $spinkit-spinner-margin;
    width: $spinkit-size * 2;
    text-align: center;

    .dot {
        width: $spinkit-size / 2;
        height: $spinkit-size / 2;
        background-color: $spinkit-spinner-color;

        @include n-theme() {
            background-color: theme(color-primary);
        }

        border-radius: 100%;
        display: inline-block;
        animation: sk-three-bounce $animationDuration ease-in-out 0s infinite
            both;
    }

    .dot1 {
        animation-delay: -$delayRange;
    }

    .dot2 {
        animation-delay: -$delayRange / 2;
    }

    &__container {
        &--full {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            display: flex;
        }
    }

    /* styles on backdrop */
    .modal-backdrop & {
        @include n-abs(all, 40px);
    }
}

@keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
