/* RELATED PRODUCTS
-------------------------------------------------------------- */
.product-carousel {
    margin: 50px auto;

    @include clearfix;

    @media #{$media-sm} {
        padding-left: 40px;
        padding-right: 50px;
    }

    @media #{$media-to-sm} {
        width: 100%;
        margin: 30px auto 0;
    }

    @media #{$media-xs} {
        padding: 20px 0 30px 0;
    }

    .catalogsearch-result-index & {
        margin-top: 0;
    }

    &__header {
        display: flex;
        max-width: 530px;
        margin: 0 auto;
        position: relative;
        height: 61px;
        min-height: 61px;
        padding-bottom: 0;

        @media #{$media-to-sm} {
            margin-bottom: 20px;
        }

        .input-group-btn {
            width: auto;
        }
    }

    &__title {
        flex: 1;
        margin: 0;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: 26px;
        font-weight: $font-weight-bold;
        line-height: 1.1;
        color: inherit;
        font-family: inherit;

        @media #{$media-to-sm} {
            font-size: 20px;
        }

        @media #{$media-xs} {
            font-size: 18px;
        }
    }

    .slick-list {
        padding: 10px 0 20px 0;
    }

    .glider-slide {
        min-width: 200px;
    }
}
