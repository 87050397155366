/* HEADINGS
-------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 0;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;

    @include n-theme() {
        //color: theme(color-primary);
    }

    small,
    .small {
        font-weight: normal;
        line-height: 1;
        color: $headings-small-color;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-bottom: ($line-height-computed / 2);

    small,
    .small {
        font-size: 65%;
    }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-bottom: ($line-height-computed / 2);

    small,
    .small {
        font-size: 75%;
    }
}

h1,
.h1 {
    font-size: $font-size-h1;
}

h2,
.h2 {
    font-size: $font-size-h2;
}

h3,
.h3 {
    font-size: $font-size-h3;
}

h4,
.h4 {
    font-size: $font-size-h4;
}

h5,
.h5 {
    font-size: $font-size-h5;
}

h6,
.h6 {
    font-size: $font-size-h6;
}

/* BODY TEXT / PARAGRAPHS
-------------------------------------------------------------- */
p {
    margin: 0 0 ($line-height-computed / 2);
    line-height: 1.6;
}

strong,
b {
    font-weight: $font-weight-bold;
}

/* ADDRESS
-------------------------------------------------------------- */
address {
    font-style: normal;
}

/* BLOCKQUOTE
-------------------------------------------------------------- */
blockquote {
    position: relative;
    margin: 30px 0;
    border-left: 2px solid #e3e3e3;
    padding-left: 80px;
    padding-top: 35px;
    font-size: 21px;
    line-height: 1.4;
    font-weight: $font-weight-light;
    font-style: italic;

    @media #{$media-xs} {
        padding-left: 60px;
    }

    &:before {
        content: '„';
        position: absolute;
        font-style: normal;
        left: 17px;
        top: -15px;
        font-size: 100px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.2);
        line-height: 0;

        @media #{$media-xs} {
            left: 8px;
        }
    }

    > p {
        margin: 0;
        line-height: 1.4;
    }
}

/* LISTS
-------------------------------------------------------------- */
dl,
ul,
ol,
dt,
dd,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list-inline {
    margin-left: -5px;

    > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;

        &.active {
            font-weight: $font-weight-bold;
        }
    }
}

.list-table {
    display: table;
    table-layout: fixed;

    > li {
        display: table-cell;
    }
}

.list-bulleted {
    padding-left: 8px;

    > li {
        position: relative;
        margin-bottom: 4px;

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $gray-dark;
            left: -9px;
            top: 8px;
        }
    }
}

.list-links {
    > li {

        @media only screen and (any-hover: hover) and (any-pointer: coarse) {
            &:hover {
                background-color: #e8edee;
                cursor: pointer;
            }
        }

        a {
            display: block;
            color: $sherpa-blue;
            width: 100%;
            background: none;
            text-align: left;
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            height: auto;
            border-top: 0;
            cursor: pointer;
            padding: 10px;

            @include n-theme() {
                color: theme(color-primary);
            }
        }
    }
}

%list-checks {
    $list-checks-icon-size: 30px;

    font-size: 16px;
    padding-top: 0.25em;

    > li {
        margin-bottom: 10px;
        position: relative;
        padding-left: 48px;
        min-height: $list-checks-icon-size;

        span {
            display: flex;
            width: $list-checks-icon-size;
            height: $list-checks-icon-size;
            position: absolute;
            top: -0.25em;
            left: 0;
            border-radius: 50%;
            background-color: $sherpa-blue;
            justify-content: center;
            align-items: center;

            .icon {
                width: 40%;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.list-checks > ul,
ul.list-checks,
.checkout-advantages-block .cms-block__content ul {
    @extend %list-checks;
}

/* TITLES
-------------------------------------------------------------- */
.page-title {
    @include clearfix();
    margin-top: 0;

    .separator {
        margin: 0 3px;
    }

    .link-rss {
        float: right;
        margin: 7px 0 0;
    }
}

.page-subtitle {
    padding-bottom: 30px;
    text-align: center;

    > * {
        margin: 0;
        font-weight: $font-weight-medium;
    }
}

.title-buttons {
    text-align: right;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        float: left;
    }
}

.title-underline {
    position: relative;
    padding-bottom: 10px;

    &:after {
        content: "";
        border-bottom: 3px solid $sherpa-blue;
        bottom: 0;
        display: block;
        position: absolute;
        width: 80px;
    }
}

/* CMS pages, mobile customer-service problem with links
-------------------------------------------------------------- */
.cms-page-view main,
.neuca-base-index-regulation {
    a {
        display: inline-block;
    }
}
.cms-page-view main {
    .cms-posts-main {
        a {
            display: initial;
        }
    }
}
