/* COLOR LABELS
-------------------------------------------------------------- */
.label-tag {
    font-size: 12px;
    font-weight: $font-weight-xbold;
    text-transform: uppercase;

    &--big {
        font-size: 15px;
    }

    &--sponsored {
        font-size: 15px;
        color: $green !important; // if sponsored is chosen, override other color options
    }
}
