// Misc
.links {
    li {
        display: inline;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.link-cart,
.link-wishlist,
.link-reorder,
.link-compare,
.link-print {
    font-weight: $font-weight-bold;
}

.btn-remove,
.btn-previous,
.btn-remove2,
.btn-edit {
    display: inline-block;
    font-size: 0;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.btn-edit:after {
    //    content: $fa-var-pencil;
}

.cards-list dt {
    margin: 5px 0 0;
}

.cards-list .offset {
    padding: 2px 0 2px 20px;
}

.separator {
    margin: 0 3px;
}

.max-price-info {
    border-radius: 4px;
    text-align: center;
    padding: 24px 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    background-color: $blue-stone;
    color: $sherpa-blue;


    @media #{$media-to-sm} {
        font-size: 12px;
        line-height: 18px;
    }

    .cms-home & {
        margin-bottom: 30px;
    }
}

.tooltip-max-price-info {
    margin: 10px 0 0 -10px;
    p {
        text-align: left;
        font-size: 12px;
        line-height: 1.3;
    }

    u {
        white-space: nowrap;
    }
}
