.frame-msg {
    background: $gray-lighter2;
    border: 1px solid #bababa;
    border-radius: 8px;
    padding: 20px 20px 25px;
    width: 100%;
    
    &__info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;

        @media #{$media-xs} {
            align-items: flex-start;
        }
    }

    &__text {
        margin-bottom: 0;
    }

    &__actions {
        display: flex;
        justify-content: center;
    }

    &__btn {
        text-transform: none;
        min-height: 40px;
        max-width: 180px;
    }
}


.frame-info {
    display: flex;
    align-items: center;
    padding: 35px 70px;
    background: rgba(242, 242, 242, 0.2);
    border: 1px solid rgba(183, 189, 196, 0.503551);
    border-radius: 24px;
    color: $blue-dianne;
    margin: 50px 0;

    @media #{$media-xs} {
       flex-direction: column;
       margin: 30px 0;
       padding:20px 30px;
    }
   
    &__icon {
        background-image: url(../icons/info-full.svg);
        width: 41px;
        height: 41px;
        display: block;
        margin-right: 30px;
        min-width: 41px;
        background-size: 100%;
        background-repeat: no-repeat;

        @media #{$media-xs} {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    p {
        font-weight: $font-weight-bold;
        text-align: center;
        font-size: 22px;
        line-height: 28px;

        @media #{$media-xs} {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.category-list {
    .category-box {

        &__banner {
            text-align: center;
        }

        .h1 {
            font-size: 20px;
            text-align: center;
            position: static;
            text-transform: none;
        }
    }
}