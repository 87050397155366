@import "./scss/global/shared.scss";
/* 1 =HELPERS
-------------------------------------------------------------- */
@import 'helpers/animate';

/* 2 =CORE
-------------------------------------------------------------- */
@import 'core/reset';
@import 'core/type';
@import 'core/forms';
@import 'core/tables';
@import 'core/content-visibility';

/* 3 =PLUGINS
-------------------------------------------------------------- */
@import 'plugins/blazy';
@import 'plugins/text-truncate';
@import 'plugins/adserver';
@import '~glider-js/glider.css';

/* 4 =COMPONENTS
-------------------------------------------------------------- */
//seems advantages are not used
@import 'components/alerts';
@import 'components/article-list';
@import 'components/backdrop';
@import 'components/badge';
@import 'components/blocks';
@import 'components/cookies';
@import 'components/color-box';
@import 'components/category-box';
@import 'components/colors';
@import 'components/images';
@import 'components/labels';
@import 'components/logos';
@import 'components/misc';
@import 'components/minicart';
@import 'components/modals';
@import 'components/newsletter';
@import 'components/pagination';
@import 'components/prices';
//pytanie ktore z tych komponentów components/product-* sa potrzebne na TYPO3?
//todo potencjalnie jest do przeniesienia do style-shop
@import 'components/new-product-item';
@import 'components/product-simple';
@import 'components/product-rx';
@import 'components/product-small';
@import 'components/product-list';
@import 'components/product-carousel';
@import 'components/product-collection';
@import 'components/spinner';
@import 'components/tab-quick';
@import 'components/toolbar';
@import 'components/tooltip';
@import 'components/link-arrow';
@import 'components/glossary';
@import 'components/lead-form';
@import 'components/faq';
@import 'components/tabs';
@import 'components/frames';
@import 'components/article-teaser';
@import 'components/faq-component';
@import 'components/search';
@import 'components/special-menu';
@import 'components/trustmate';
@import 'components/cookiefirst';

/* 5 =LAYOUT
-------------------------------------------------------------- */

/* 6 =CONTENT
-------------------------------------------------------------- */
@import 'content/std';
