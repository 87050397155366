/* ALERT VARIANT MIXINS
-------------------------------------------------------------- */
@mixin alert-variant($background, $border, $text-color) {
    background-color: $background;
    border-color: $border;
    color: $text-color;
}

/* ALERTS
-------------------------------------------------------------- */
.error-msg,
.success-msg,
.newsletter-success-msg,
.note-msg,
.notice-msg,
.global-site-notice {
    position: relative;
    display: table;
    margin-bottom: 40px;
    width: 100%;
    padding: 20.5px 0;
    text-align: center;
    box-shadow: 0 2px 0 palette(red, dark);
    background: palette(red);
    color: #fff;
    overflow: hidden;

    &:before {
        //@include icon();
        //content: "\e917";
        font-size: 75px;
        position: absolute;
        left: 40px;
        top: -7px;
        bottom: -7px;
        height: 75px;
        margin: auto;
        overflow: hidden;
        @include rotate(25deg);

        @media #{$media-to-sm} {
            height: 59px;
            font-size: 59px;
        }

        @media #{$media-xs} {
            display: none;
        }
    }

    ul {
        display: table-cell;
        vertical-align: middle;

        @media #{$media-xs} {
            padding: 0 15px;
        }

        li + li {
            margin-top: 10px;
        }

        span {
            display: inline-block;
            width: 70%;
            margin: auto;

            @media #{$media-to-sm} {
                text-align: left;
                font-size: 13px;
            }

            @media #{$media-xs} {
                text-align: center;
                width: 100%;
            }
        }
    }

    .close {
        position: absolute;
        right: -15px;
        top: -15px;
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background: #fff;
        box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
        color: palette(red);
        cursor: pointer;

        @include n-transition;

        &:hover {
            background: palette(red);
            color: #fff;
        }

        i {
            line-height: 35px;
            font-weight: bold;
        }
    }
}

/* magento messages container */
.messages {
    > li {
        margin-bottom: 30px;
        box-shadow: none;
    }

    @media #{$media-xs} {
        margin-left: -15px;
        margin-right: -15px;
    }
}

/* ALERTS COLORS
-------------------------------------------------------------- */
.success {
    color: $state-success-text;
}

.error {
    color: $state-danger-text;
}

.notice {
    color: $state-info-text;
}

.success-msg {
    @include alert-variant(
        $alert-success-bg,
        $alert-success-border,
        $alert-success-text
    );
    box-shadow: 0 2px 0 #005000;
}

.newsletter-success-msg {
    background-color: #065360;
    box-shadow: none;
}

.note-msg,
.notice-msg {
    @include alert-variant(
        $alert-info-bg,
        $alert-info-border,
        $alert-info-text
    );
    box-shadow: 0 2px 0 #31708f;

    a {
        text-decoration: underline;
        color: inherit;
    }
}
