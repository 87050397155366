/* STYLES FOR MAGENTO/TYPO3 CONTENT
-------------------------------------------------------------- */
.std {
    /* hide unexpected br after elements */
    ul + br,
    ul > br,
    li + br,
    p + br,
    br + br {
        display: none;
    }

    /* LISTS
    -------------------------------------------------------------- */
    ul,
    ol {
        margin: 0 0 20px 20px;

        li {
            margin-bottom: 10px;
            list-style-type: disc;
        }
    }

    ol {
        li {
            list-style-type: decimal;
        }
    }

    /* TABLE
    -------------------------------------------------------------- */
    table {
        @extend .table-content;
    }
}

.text-page {
    .ce-textpic,
    .frame-type-text {
        table {
            @extend .table-content;
        }
    }
}
