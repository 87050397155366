.products-list {
    .product-item {
        border-radius: 24px;
        padding: 5px 5px 20px;

        @media #{$media-xs} {
           margin-right: 20px;
        }

        &__shop {
            padding: 0 5px;
        }

        &__desc {
            padding: 10px 10px 5px 7px;
        }

        &__img {
            border-bottom: none;
            min-height: 175px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 0;

            img {
                max-height: 110px;
                max-width: 110px;
                width: auto;
            }
        }

        &__btn {
            border-radius: 24px;
            padding: 10px 16px;

            .icon {
                @media #{$media-xs} {
                    margin-left: 18px;
                    margin-right: 0;
                 }
            }
        }

        &__rx {
            max-width: 170px;
            margin: auto;
            top: 20px;

            strong {
                font-size: 17px;
            }
        }

        .price {
            display: block;

            @media #{$media-xs} {
               font-size: 22px;
            }
        }

        .product-label {
            height: auto;
        }

        .tooltip-max-price-info {
            margin: 10px 0 0 0;

            p {
                font-size: 11px;
                color: $blue-dianne;
            }
        }
    }
}

// todo refactor after widgets merge
.category-box {
    .product-item {
        &__img {
            img {
                max-height: 190px;
                max-width: 190px;
                width: auto;
            }
        }
    }
}