.article-teaser {
    flex: 1;
    padding-bottom: 3px;

    @media #{$media-xl} {
        max-width: 310px;
        margin: 0 20px;
    }

    @media #{$media-lg} {
        flex: 1;
        max-width: 320px;
        margin: 0 40px;
        padding-bottom: 3px;
    }

    @media #{$media-md} {
        margin: 0 10px;
        max-width: 330px;
    }

    @media #{$media-sm} {
        margin: 0 10px;
        max-width: 300px;
    }

    @media #{$media-xs} {
        display: block;
    }

    &:focus {
        @include n-theme() {
            outline-color: transparent;
        }
    }

    &__wrapper {
        @media #{$media-xs} {
            width: 245px;
            margin: 0 auto;
        }
    }

    &__img {
        width: 100%;
        height: 221px;
        margin-bottom: 7px;
        display: block;

        @media #{$media-xl} {
            max-width: 310px;
        }

        @media #{$media-lg} {
            min-width: 320px;
        }

        @media #{$media-md} {
            max-width: 330px;
        }

        @media #{$media-sm} {
            margin-right: 20px;
            max-width: 300px;
        }

        @media #{$media-xs} {
            height: 216px;
            margin: 0 auto;
        }

        img {
            border-radius: 12px;
            max-height: 100%;
            max-width: 100%;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__desc {
        @media #{$media-xs} {
            margin-top: 7px;
        }
    }

    &__category {
        font-size: 16px;
        font-weight:$font-weight-xbold;
        position: relative;
        padding-bottom: 4px;
        margin-bottom: 7px;
        display: inline-block;
        @extend .title-underline;

        &:after {
            width: 80%;
        }

        &:hover {
            color: $sherpa-blue;
        }
    }

    &__data {
        display: flex;
        align-items: flex-start;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: $blue-dianne;
        margin-bottom: 7px;
    }

    &__author {
        padding-right: 20px;
        position: relative;
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        min-height: 18px;
    }

    &__date {
        margin-bottom: 0;

        &:after {
            content: '';
            display: block;
            width: 1px;
            height: 11px;
            position: absolute;
            top: 3px;
            background-color: $blue-dianne;
        }
    }

    &__title {
        color: $blue-dianne;
        font-size: 16px;
        line-height: 22px;
        font-weight: $font-weight-xbold;
        margin-bottom: 10px;
        min-height: 60px;

        &:hover {
            color: $sherpa-blue;
        }

        @media #{$media-xs} {
           display: block;
           min-height: 1px;
        }
    }

    &__text {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
    }

    &__more {
        border-radius: 24px;
        border: 1px solid $sherpa-blue;
        text-transform: none;
        min-width: 140px;
        min-height: 40px;
        height: 40px;
        font-weight: $font-weight-xbold;
    }

    .details-btn {
        color: $sherpa-blue;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}


.article-section {
    position: relative;
    padding: 0 0 40px;

    @media #{$media-xs} {
        padding: 0 0 30px;
    }

    &__title {
        font-size: 22px;
        margin-bottom: 35px;
        font-weight: $font-weight-xbold;
        padding-bottom: 15px;

        &:after {
            width: 85px;
        }

        @media #{$media-xs} {
            font-size: 20px;
        }
    }

    &__slider {
        padding: 0 45px;

        @media #{$media-md} {
            padding: 0 35px;
        }

        @media #{$media-xs} {
            padding: 0;
        }
    }

    &__wrapper {
        display: flex;
    }


    &__arrow {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: auto;
        opacity: 1;
        position: absolute;
        top: 55%;

        @media #{$media-xs} {
            top: 52.5%;
            visibility: visible;
        }

        &--left {
            left: 6px;
            transform: rotate(-180deg);
        }

        &--right {
            right: 6px;
        }
    }

    &--slider {
        .article-section {
            &__title {
                @media #{$media-xs} {
                   font-size: 20px;
                }
            }
        }
        .article-teaser {
            margin: 0 25px;

            @media #{$media-xs} {
                padding-bottom: 5px
            }

            &__img {
                @media #{$media-lg} {
                    min-width: 298px;
                    height: 216px;
                    margin-bottom: 5px;
                }
            }

            &__title {
                height: 60px;
                display: -webkit-box;
            }

            &__category {
                margin-bottom: 11px;

                &:after {
                    @media #{$media-sm-up} {
                        display: none;
                    }
                }
            }

            &__data {
                margin-bottom: 10px;
            }

            &__date {
                position: relative;

                &:after {
                    left: -10px;
                    top: 4px;
                }
            }

            &__text {
                height: 88px;
            }
        }
    }

    &--bg {
        background-color: $sherpa-blue;
        border-radius: 8px;
        padding: 39px 16px 0;
        margin-top: 10px;
        margin-bottom: 30px;

        @media #{$media-xs} {
            padding: 19px 16px 0;
        }

        .article-section {
            &__title {
                color: #fff;
                padding-left: 16px;

                &:after {
                    border-bottom: 3px solid $red-btn;
                }
            }

            &__slider {
                padding: 0 16px;

                @media #{$media-xs} {
                   padding: 0 25px;
                }
            }

            &__wrapper {
                display: flex;
                flex-wrap: wrap;

                @media #{$media-to-sm} {
                    flex-direction: column;
                }
            }

            &__arrow {
                @media #{$media-xs} {
                    top: 53.5%;
                }

                &--left {
                    @media #{$media-xs} {
                        left: 18px;
                    }
                }

                &--right {
                    @media #{$media-xs} {
                        right: 18px;
                    }
                }
            }
        }

        .article-teaser {
            min-width: 45%;
            width: 45%;
            max-width: unset;
            color: white;
            margin: 0 0 35px;

            &:nth-child(n+5) {
                display: none;

                @media #{$media-xs} {
                   display: block;
                }
            }

            @media #{$media-sm} {
                width: 100%;
            }

            @media #{$media-xs} {
                width: auto;
                min-width: unset;
            }

            &__wrapper {
                display: flex;
                align-items: center;

                @media #{$media-md} {
                    align-items: flex-start;
                }

                @media #{$media-xs} {
                    flex-direction: column;
                }
            }

            &__img {
                @media #{$media-lg} {
                    max-width: 238px;
                    max-height: 248px;
                    min-width: 238px;
                    height: 248px;
                    margin-right: 20px;
                }

                @media #{$media-md} {
                    height: 200px;
                    width: 200px;
                    min-width: 200px;
                    margin-right: 15px;
                }

                @media #{$media-sm} {
                    max-width: 240px;
                }
            }

            &__desc {
                margin-right: 20px;

                @media #{$media-xs} {
                    margin-right: 0;
                }
            }

            &__category {
                color: #fff;
                margin-bottom: 6px;

                &:after {
                    border-bottom: 3px solid $red-btn;
                    min-width: 62px;
                    width: 90%;
                }
            }

            &__data,
            &__title {
                color: #fff;
            }

            &__title {
                height: 60px;
                display: -webkit-box;
                margin-bottom: 5px;

                @media #{$media-md} {
                   height: 42px;
                   min-height: 42px;
                   margin-bottom: 10px;
                   line-height: 24px;
                   font-weight: $font-weight-bold;
                }
            }

            &__more {
                color: #fff;
                border-color: #fff;
                background: transparent;
            }

            &__date {
                position: relative;

                &:after {
                    top: 4px;
                    left: -10px;
                    background-color: #fff;
                }
            }

            &__text {
                margin-bottom: 12px;

                @media #{$media-xs} {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
