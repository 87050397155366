/* PRODUCT ITEM SIMPLE
-------------------------------------------------------------- */
.product-simple {
    $c: &;
    text-align: center;

    /* unavailable state */
    &--unavailable {
        img {
            min-height: 100px;
            opacity: 0.25;
        }

        &.product-simple--refunded {
            img {
                opacity: 0;
            }
        }

        .product-item__rx {
            opacity: 0.5;
        }

        .price-box {
            opacity: 0.65;
        }
    }

    &__img {
        position: relative;
        max-height: 152px;
        overflow: hidden;
    }

    &__name {
        height: 40px;
        font-size: 13px;
        line-height: 18px;
        font-weight: $font-weight-bold;
    }

    &__price {
        .price-box {
            font-size: 26px;

            &--rx {
                font-size: 15px;
                line-height: 37px;
            }
        }
    }

    .product-item__rx {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .product-rx__info {
        p {
            margin: 0;
            left: 0;
            right: 0;
        }

        .badge-icon {
            margin: 0;
            width: 105px;
            height: 105px;
            line-height: 103px;
        }
    }
}
