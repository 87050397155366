/* LEAD FORM WIDGET - YOU SHOULD UPDATE STYLES IN ADMIN PANELL TOO!
-------------------------------------------------------------- */

.lead-form {
    padding: 60px 80px 60px 120px;
    background: #fafafa;
    width: 782px;
    max-width: 100%;
    margin-bottom: 20px;
    @media #{$media-md} {
        padding: 40px;
    }
    @media #{$media-md} {
        padding: 60px 70;
    }
    @media #{$media-xs} {
        padding: 28px;
    }
    button,
    button.agreement-expand-text {
        &,
        &:hover,
        &:focus {
            border: none;
            display: inline;
            text-transform: none;
            font-size: inherit;
            min-height: auto;
            min-width: auto;
            vertical-align: baseline;
            background-color: none;
        }
    }
    .agreement-expand-text {
        &,
        &:hover,
        &:focus {
            color: #126a86;
            background: none;
            padding: 0;
            margin: 0;
        }
    }
    h2 {
        width: 66%;
        font-size: 24px;
        margin: 0 auto 50px;
        @media #{$media-xs} {
            width: 100%;
            font-size: 20px;
            margin: 0 auto 32px;
        }
        font-weight: 700;
        color: $sherpa-blue;
        text-align: center;
    }
    &__content,
    &__footer {
        display: flex;
        @media #{$media-xs} {
            flex-direction: column;
        }
    }

    &__img {
        @media #{$media-md-up} {
            min-width: 265px;
            max-width: 280px;
            height: 349px;
        }

        @media #{$media-xs} {
           margin-bottom: 20px;
        }

        img {
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    ul {
        margin-top: 0;
        margin-left: 35px;

        @media #{$media-xs} {
            margin-left: 24px;
            margin-right: 0;
        }
        list-style: disc;
        li {
            font-size: 18px;
            line-height: 1.3;
            margin-bottom: 18px;
            list-style: disc;
        }
    }
    &__input {
        border-radius: 0;
        height: 40px;
        font-style: normal;
        width: 100%;
        padding: 0 12px;
        border: 1px solid rgb(150, 150, 150);
        width: 265px;
        @media (min-width: 470px) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
        @media #{$media-sm-up} {
            width: 296px;
            margin-left: 0;
        }
        color: rgba(0, 0, 0, 0.67);
        font-size: 13px;
    }
    &__fieldset {
        .validation-agreement {
            display: none;
        }
        &.validation-failed {
            .validation-agreement {
                display: block;
            }

            .lead-form__checkbox {
                border: 2px solid #f00000;
            }
        }
    }
    &__agreement {
        display: flex;
        margin-top: 10px;
        width: 100%;
        a {
            color: #126a86;
        }
        @media (min-width: 450px) {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
        @media #{$media-sm-up} {
            width: 272px;
            margin-left: 0;
        }

        @media #{$media-xs} {
            text-align: left;
            margin-bottom: 15px;
        }
    }

    &__check-input {
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        pointer-events: none;
        &:checked + .lead-form__checkbox,
        &:checked ~ .lead-form__checkbox {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='7' fill='%23f00000' viewBox='0 0 17.103 13.6' style='enable-background:new 0 0 17.103 13.6' xml:space='preserve'%3E%3Cpath d='M15.98.247 6.972 12.064 1.038 6.85a.625.625 0 1 0-.825.94l6.438 5.656c.115.1.262.154.412.154.022 0 .041 0 .063-.002a.626.626 0 0 0 .436-.244l9.414-12.35a.627.627 0 0 0-.117-.877.63.63 0 0 0-.879.12z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px;
        }
    }
    &__check-input,
    &__checkbox {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        box-sizing: border-box;
    }
    &__check-container {
        position: relative;
        min-width: 20px;
        height: 20px;
        margin-right: 17px;
    }
    &__check-label {
        width: 100%;
        height: 100%;
        display: block;
        &:focus-within {
            border-width: 2px;
            outline: none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
        }
    }
    &__checkbox {
        min-width: 20px;
        height: 20px;
        border: 1px solid #111;
        display: block;
        cursor: pointer;
        background-color: #fff;
    }
    &__label {
        font-size: 10px;
        color: #111;
        width: 235px;
        line-height: 1.2;
    }
    .lead-form__button {
        font-weight: 600;
        font-size: 15px !important;
        line-height: 1.5;
        margin-left: 14px;
        color: #fff;
        min-width: 236px;
        @media #{$media-xs} {
            margin: 22px 0 0;
        }
        @media (min-width: 350px) {
            margin: 22px 10px 0 10px;
        }
        @media #{$media-sm-up} {
            margin: 0px 10px 0 10px;
        }
        border-radius: 24px;
        padding: 9.5px 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $sherpa-blue;
        height: 40px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        @media #{$media-xs} {
            max-width: 280px;
        }
        &,
        &:hover,
        &:focus {
            padding: 0 14px;
            min-width: 236px;
            margin: 0px 10px;
            color: #fff;
            background: $dark-green;
        }
    }
    &__error {
        color: red;
        display: block;
        font-size: 13px;
        text-align: center;
        margin-top: 8px;
    }
    &__footer {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 50px !important;
        margin-left: 20px;
        @media #{$media-xs} {
            align-items: stretch;
            margin-top: 20px !important;
            margin-left: 0;
        }

        form {
            display: flex;

            @media #{$media-xs} {
               flex-direction: column;
               text-align: center;
            }
        }
    }

    .validation-advice {
        font-size: 12px;
    }
}
