/* FORM INPUTS
-------------------------------------------------------------- */
input,
textarea,
select,
.input-custom {
    display: block;
    width: 100%;
    height: $input-height-base;
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: 15px;
    font-style: italic;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;
    background-image: none;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;

    /**
     Code below works only in mobile Safari.
     Resolves this issue: https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
     Issue is resolved in mobile Safari > 15.0 - remove this after Safari 16 release.
     */
    @supports (-webkit-touch-callout: none) {
        font-size: 16px !important;
    }
    // end of Safari quirk

    &:active:enabled,
    &:focus:enabled {
        border-width: 2px;
        outline: none;
        outline-offset: 0;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }

    &:disabled,
    &[readonly] {
        border-color: rgba($input-border, 0.5);
        color: rgba($input-color, 0.5);
        cursor: default;
        pointer-events: none;
        opacity: 1;
        @include user-select(none);
    }

    &.is-failed,
    .ng-submitted &.ng-invalid {
        border-color: palette(red);
        background: #fef8f8;
    }

    &.browser-reset {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
}

select {
    min-width: 110px;
    max-width: 100%;
    height: 43px;
    font-style: normal;
    font-size: 15px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='100' viewBox='0 0 200 50' fill='%238C98F2'%3E%3Cline x1='0' y1='0' x2='50' y2='50' stroke='black' stroke-width='5' /%3E%3Cline x1='100' y1='0' x2='50' y2='50' stroke='black' stroke-width='5' /%3E%3C/svg%3E");
    background-position: 100% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    margin-bottom: 0;
    appearance: none;
    outline: none;
    padding-right: 40px;
    background-color: white;

    option {
        color: black;
        background-color: white;
        font-style: normal;
        appearance: none;
        font-size: 15px;
        outline: none;
        box-shadow: 0 0 10px 100px red inset;

        &:checked {
            position: relative;
            &::after {
                content: attr(value);
                background: pink;
                color: #fff;
                position: absolute;
                width: 100%;
                left: 0;
                border: none;
            }
        }

        &:hover,
        &:active,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &:-moz-focusring,
        &::-moz-focus-inner,
        &::-moz-focus-outer {
            color: green !important;
            font-weight: bold !important;
            background-color: lightblue;
            box-shadow: 0 0 10px 10px #e1358f inset;
        }
    }
}

select option:checked::after {
    content: attr(value);
    background: pink;
    color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
}

textarea {
    min-height: 160px;
    padding: 20px;
    resize: none;
}

/* LABEL
-------------------------------------------------------------- */
label {
    &.is-failed {
        color: palette(red);
    }
}

/* INPUT CUSTOM
-------------------------------------------------------------- */
.input-custom {
    &--text {
        padding-top: 0;
        line-height: 48px;
    }

    &--large {
        height: $input-height-large;
    }

    &--small {
        height: $input-height-small;
    }

    &--gray-light {
        border-color: $gray-light--hover;
    }
}

/* SELECT CUSTOM
-------------------------------------------------------------- */
.select {
    /* custom select wrapper */
    &-wrapper {
        position: relative;

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding-right: 40px;

            &::-ms-expand {
                display: none;
            }
        }
    }

    /* state after plugin init */
    &-custom {
        @include n-transition(all);

        .label {
            padding-right: 25px;
        }

        /* options list */
        &-items {
            padding-bottom: 10px;
            background: #fff;
            border: 1px solid $input-border;
            border-top: 0;
            border-bottom-left-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;
            font-size: 14px;
            z-index: 1001;

            ul {
                width: 100%;
            }

            li {
                margin: 0;
                padding: 10px 15px 10px 20px;

                &.selected {
                    background: palette(gray, light);
                }
            }
        }

        /* open state */
        &-open {
            /* small inception ;-) */
            .select-custom {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            /* open from top */
            &.select-custom-above {
                .select-custom {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: $input-border-radius;
                    border-bottom-right-radius: $input-border-radius;

                    &-items {
                        padding: 10px 0 0 0;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: $input-border-radius;
                        border-top-right-radius: $input-border-radius;
                        border-top: 1px solid $input-border;
                        border-bottom: 0;
                    }
                }
            }
        }

        /* hover state */
        &-hover {
            .select-custom-items {
                &:hover {
                    .selected {
                        background: transparent;
                    }
                }

                li {
                    &:hover {
                        background: rgba($blue, 0.2);
                        @include n-theme() {
                            background: rgba(theme(addtocart-color), 0.2);
                        }
                    }
                }
            }
        }
    }
}

/* CHECKBOXES / RADIOS
-------------------------------------------------------------- */
.radio-custom,
.checkbox-custom {
    position: absolute;
    text-indent: -99999px;
    left: -9999px;
    border-width: 0;
    width: 20px;
    height: 20px;

    &:checked + label {
        border: 1px solid $sherpa-blue;
    }

    & + label {
        display: inline-block;
        position: relative;
        margin: 0 10px 0 0;
        width: 20px;
        height: 20px;
        border: 1px solid $blue-dianne;
        background: #fff;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 4px;

        @media #{$media-sm} {
            width: 30px;
            height: 30px;
        }

        @media #{$media-xs} {
            width: 20px;
            height: 20px;
        }

        & + label {
            margin: 0;
            vertical-align: middle;
        }
    }

    &:checked {
        & + label::before {
            content: '';
            color: palette(red);
        }
    }

    &:focus {
        & + label {
            @include n-theme() {
                border-color: theme(color-primary);
            }
        }
    }

    &:disabled {
        & + label {
            cursor: default;
        }
    }

    &.radio-custom {
        & + label {
            border-radius: 100%;
        }

        &:checked {
            & + label:before {
                @include n-abs(all, 8px, 8px);
                border-radius: 100%;
                border: 1px solid $sherpa-blue;

                @include n-theme() {
                    background: theme(color-primary);
                }

                @media #{$media-to-sm} {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &.checkbox-custom {
        & + label::before {
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            opacity: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 65%;
            background-image: url('data:image/svg+xml,<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.19958L4.5 8.19958L1 5.19958" stroke="%23007F32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }

        &:checked + label::before {
            opacity: 1;
        }
    }

    &.ng-dirty.ng-invalid,
    &.validation-failed {
        & + label {
            background: #fef8f8;
            border-width: 2px;
            border-color: palette(red);
        }
    }
}

/* AGREEMENTS GROUP
-------------------------------------------------------------- */
.agreements-group {
    &__options {
        margin-bottom: 20px;

        &.input-group {
            @media #{$media-to-md} {
                display: block;
            }

            & > div:nth-child(n + 2) {
                @media #{$media-lg} {
                    margin-left: 10px;
                }

                @media #{$media-to-md} {
                    margin-top: 10px;
                }
            }
        }
    }

    &__option {
        &.input-group-btn {
            @media #{$media-to-sm} {
                display: block;
            }
        }

        & + div {
            padding-left: 10px;

            @media #{$media-to-sm} {
                padding: 0;
                margin-top: 10px;
            }
        }

        label {
            vertical-align: middle;
        }

        &--suggested {
            color: #5a5a5a;
            font-weight: $font-weight-bold;

            .checkbox-custom {
                & + label {
                    border: 2px solid #5a5a5a;
                }
            }
        }
    }

    &__container {
        font-size: 13px;
        //        padding: 25px 25px 25px 30px;
        //        border-radius: $border-radius-large;
        //        border: 1px solid palette(gray);
        //        background-color: rgba(255,255,255,.5);

        @media #{$media-to-sm} {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }

    &__warning {
        margin-bottom: 20px;
    }

    &__field {
        margin-bottom: 30px;

        em {
            display: inline-block;
            color: palette(red);
        }

        input[type='checkbox'] + label {
            margin: 0 10px 0 0;
        }

        .input-group-btn {
            vertical-align: top;
        }

        .validation-advice {
            display: block;
            margin-top: 5px;
        }

        label {
            a {
                text-decoration: underline;
            }
        }

        .tooltip-icon {
            @media #{$media-xs} {
                display: block;
            }
        }

        .tooltip-inner {
            min-width: 300px;
            white-space: pre-wrap;

            @media #{$media-xs} {
                margin-left: 10px;
            }

            @media #{$media-md-up} {
                min-width: 450px;
                max-width: 450px;
            }
        }
    }

    .scroll-custom {
        max-height: 285px;
        padding-right: 20px;
        overflow: hidden;
    }

    .validation-advice {
        display: inline;
    }
}

/* VALIDATION STATE
-------------------------------------------------------------- */
.is-failed {
}

/* VALIDATION ADVICE
-------------------------------------------------------------- */
.validation-advice {
    display: block;
    padding-top: 5px;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: palette(red);
}

.validation-advice-child {
    display: block;
    padding-top: 5px;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    color: palette(red);
}

.validation-tip {
    margin-top: 5px;
    font-size: 12px;
}

/* FORM
-------------------------------------------------------------- */
.form {
    label {
        display: inline-block;
        margin-bottom: 5px;
        font-weight: bold;

        em {
            color: palette(red);
        }
    }

    /* control row */
    &__row {
        margin-bottom: 25px;
    }
}

/* @todo this required refactore - too global label styles */
.standard-form {
    @media #{$media-sm-up} {
        max-width: 460px;
    }

    &__heading {
        color: $blue;
        margin-bottom: 5px;
        margin-top: 40px;

        @include n-theme() {
            color: theme(color-primary);
        }

        @media #{$media-xs} {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }

    label {
        display: inline-block;
        margin: 20px 0 5px 0;
        font-weight: $font-weight-bold;

        em {
            color: palette(red);
            float: right;
            margin-right: 3px;
            margin-left: 0;
        }
    }

    /* @todo remove after refactore */
    .agreements-group {
        label {
            margin: 0;

            em {
                display: inline;
                float: none;
            }
        }

        &__option {
            input + label {
                margin-right: 10px;
            }
        }

        &__field {
            label {
                font-weight: normal;
            }
        }
    }
}

/*  =OLD MAGENTO IMPLEMENTATIONS @todo to remove
-------------------------------------------------------------- */

// Customer
.form-list {
    // Pattern for combinations below is
    // prefix-FIRSTNAME-middlename-LASTNAME-suffix
    // where FIRSTNAME and LASTNAME are more important
    // than prefix, middlename and suffix. Present
    // item are represented by a number and a dash.
    // This ensures we hit every concevable combination

    // 1-1-0-1-0
    .customer-name-prefix {
        .name-prefix {
            width: 16%;
        }

        .name-firstname,
        .name-lastname {
            width: 42%;
        }
    }

    // 1-1-1-1-0
    .customer-name-prefix-middlename {
        .name-prefix,
        .name-middlename {
            width: 16%;
        }

        .name-firstname,
        .name-lastname {
            width: 34%;
        }
    }

    // 1-1-0-1-1
    .customer-name-prefix-suffix {
        .name-prefix,
        .name-suffix {
            width: 16%;
        }

        .name-firstname,
        .name-lastname {
            width: 34%;
        }
    }

    // 1-1-1-1-1
    .customer-name-prefix-middlename-suffix {
        .name-prefix,
        .name-middlename,
        .name-suffix {
            width: 12%;
        }

        .name-firstname,
        .name-lastname {
            width: 32%;
        }
    }

    // 0-1-1-1-0
    .customer-name-middlename {
        //.name-middlename {
        //    width: 16%;
        //}
        //.name-firstname,
        //.name-lastname {
        //    width: 42%;
        //}
    }

    // 0-1-1-1-1
    .customer-name-middlename-suffix {
        .name-middlename,
        .name-suffix {
            width: 16%;
        }

        .name-firstname,
        .name-lastname {
            width: 34%;
        }
    }

    // 0-1-0-1-1
    .customer-name-suffix {
        .name-suffix {
            width: 16%;
        }

        .name-firstname,
        .name-lastname {
            width: 42%;
        }
    }

    // Date of birth is easy,
    // it's just 30% 30% 40%
    .customer-dob {
        .dob-day,
        .dob-month,
        .dob-year {
            float: left;
            padding-right: ($grid-gutter-width / 2);
            padding-left: ($grid-gutter-width / 2);

            &:first-child {
                padding-left: 0;
            }
        }

        // Unfortunately, the last child is not the
        // last element. However we can use a clever
        // selector to find this out.
        .dob-day + .dob-month + .dob-year,
        .dob-month + .dob-day + .dob-year,
        .dob-year + .dob-day + .dob-month,
        .dob-year + .dob-month + .dob-day {
            padding-right: 0;
        }

        .dob-day,
        .dob-month {
            width: 30%;
        }

        .dob-year {
            width: 40%;
        }
    }
}

// Button sets
.buttons-set {
    @include clearfix();
    margin: 4em 0 0;
    padding: 8px 0 0;
    border-top: 1px solid $legend-border-color;
    line-height: $input-height-base;
    text-align: right;
}

.buttons-set p.required {
    margin: 0 0 10px;
}

.buttons-set .back-link {
    float: left;
    margin: 0;
}

.buttons-set button.button {
    float: right;
    margin-left: 5px;
}

.buttons-set-order {
    margin: 10px 0 0;
}

.buttons-set-order .please-wait {
    padding: 12px 7px 0 0;
}

.validation-failed {
    border-color: $alert-danger-text !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); // Redeclare so transitions work

    &:focus {
        border-color: darken($alert-danger-text, 10%) !important;
        $shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 6px lighten($alert-danger-text, 20%);
        box-shadow: $shadow !important;
    }
}

p.required {
    text-align: right;
    color: $alert-danger-text;
}

.customer-form {
    label {
        display: inline-block;
        margin-bottom: 5px;
        font-weight: bold;

        em {
            color: #f00000;
        }
    }

    .input-box,
    .legend {
        margin-bottom: 20px;
    }

    .buttons-set {
        button,
        a {
            padding: 0;
        }

        a {
            float: left;
        }
    }

    .form-list {
        width: 50%;

        @media #{$media-xs} {
            width: 100%;
        }
    }
}

.customer-table {
    margin-bottom: 10px;

    tr:hover,
    td:hover {
        background-color: transparent !important;
    }


    thead,
    tr {
        border-bottom: 1px solid #afafaf;
    }

    thead th {
        text-align: left;
        vertical-align: bottom;
        padding: 20px 10px;
    }

    tbody td {
        text-align: left;
        padding: 20px 10px;
        height: auto;
    }
}

/* ============================================ *
 * Helpdesk Form (View Ticket subpage)
 * ============================================ */

.aw-hdu3-customer-viewticket {
    .awhdu3-customer-ticketview-page-title + .col2-set {
        display: none;
    }

    .back {
        margin-top: 10px;
    }
}

// IE11
// :) :| :(
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    select {
        &::-ms-expand {
            display: none;
        }

        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAMNJREFUSEvt0z2KQjEUhuFHBjegGxncgj+9WLgTOys7d2JlZaWuQHApYivCMBxQuMg1uUHs7ukCyffmvCfp+HJ1vpyvBWQNt4o+UtTDAkvc3yR1scIal7o9qRkcMMQW8xpIhG8wxRGjUsAvAtLHDjPcHiHV8CsmOJUCYn8d5K9y82R4BDR5pgPsETMJXVGhJZyPcU5NugngtZNYZ2/+hDYFVCE/Keev3ZQA4mzoikpqqUJKAdmP9WkHLaDYQPZAO+Sson/CLx8Zv9zDBgAAAABJRU5ErkJggg==') !important;
    }
}
