$tooltip-arrow-width: 12px;
$tooltip-arrow-height: 6px;
$tooltip-arrow-color: $sherpa-blue;

/* TOOLTIPS
-------------------------------------------------------------- */
.tooltip {
    @media #{$media-xs} {
        z-index: 21474836400;
    }

    pointer-events: none;

    &--red {
        .tooltip-inner {
            border-color: red !important;
        }

        .arrow, .tooltip-arrow {
            color: red !important;
        }
    }

    &--rte {
        .arrow,
        .tooltip-arrow {
            border-top-color: $sherpa-blue !important;
            border-bottom-color: $sherpa-blue !important;
        }


        .tooltip-inner {
            border: 3px solid $sherpa-blue;
            width: 100%;
            max-width: 300px;

            @media #{$landscape-xs} {
                max-width: 460px;
            }
        }

        * {
            text-align: left;

            @media #{$media-xs} {
                font-size: 12px;
            }

            list-style-type: disc;

            ul {
                margin: 5px 0 0 15px;
            }
        }
    }

    &-inner {
        max-width: 320px;
        padding: 15px;
        box-shadow: -5px 0 25px rgba(25, 25, 25, 0.1);
        border-radius: $border-radius-large;
        border: 3px solid $sherpa-blue;
    }

    &.show {
        opacity: 1;
        z-index: 10;
    }

    .arrow, .tooltip-arrow {
        position: absolute;
        display: block;
        width: $tooltip-arrow-width;
        height: $tooltip-arrow-height;
        color: $tooltip-arrow-color;

        &::before {
            position: absolute;
            content: '';
            border-color: transparent;
            border-style: solid;
        }
    }
}

/* TOOLTIP INFO ICON
-------------------------------------------------------------- */
.tooltip-icon {
    display: inline-block;
    width: 24px;
    height: 25px;
    background: palette(green);
    color: #fff;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
    cursor: pointer;

    @include n-theme() {
        background: theme(background-primary);
    }

    i {
        line-height: 24px;
        font-size: 13px;
    }

    &--small {
        width: 20px;
        height: 20px;
        line-height: 18px;
        margin-left: 3px;

        i {
            line-height: 1;
            font-size: 10px;
        }
    }
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }
}

.tooltip-wrapper:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Added from BS 4 stylesheet.
-------------------------------------------------------------- */

.bs-tooltip-top {
    padding: $tooltip-arrow-height 0;
    transform: translateY(-10px);

    .arrow, .tooltip-arrow {
        bottom: 0;

        &::before {
            top: 0;
            border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            border-top-color: currentColor;
        }
    }

    .tooltip-arrow {
        &::before {
            top: -3px;
        }
    }
}

.bs-tooltip-right {
    padding: 0 $tooltip-arrow-height;

    .arrow, .tooltip-arrow {
        left: 0;
        width: $tooltip-arrow-height;
        height: $tooltip-arrow-width;

        &::before {
            right: 0;
            border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            border-right-color: currentColor;
        }
    }
}

.bs-tooltip-bottom {
    padding: $tooltip-arrow-height 0;
    transform: translateY(10px);

    .arrow, .tooltip-arrow {
        top: 0;

        &::before {
            bottom: 0;
            border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            border-bottom-color: currentColor;
        }
    }
}

.bs-tooltip-left {
    padding: 0 $tooltip-arrow-height;

    .arrow, .tooltip-arrow {
        right: 0;
        width: $tooltip-arrow-height;
        height: $tooltip-arrow-width;

        &::before {
            left: 0;
            border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            border-left-color: currentColor;
        }
    }
}

.bs-tooltip-auto {
    &[x-placement^='top'] {
        @extend .bs-tooltip-top;
    }

    &[x-placement^='right'] {
        @extend .bs-tooltip-right;
    }

    &[x-placement^='bottom'] {
        @extend .bs-tooltip-bottom;
    }

    &[x-placement^='left'] {
        @extend .bs-tooltip-left;
    }
}
