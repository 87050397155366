/* PRODUCT SMALL
-------------------------------------------------------------- */
.product-small {
    &__img {
        position: relative;
        margin-bottom: 15px;
    }

    &__name,
    &__price {
        text-align: center;
    }

    &__name {
        margin: 0;
        font-size: 13px;
        color: #000;
        line-height: 1.4;
    }

    .product-img {
        margin: 0 auto;
    }

    .product-rx {
        &__info {
            align-self: center;

            p {
                margin-bottom: auto;
                left: 0;
                right: 0;
            }

            strong {
                display: block;
                margin-top: 1rem;
                font-size: 1.5rem;
                line-height: 1.1;

                @include n-theme() {
                    color: theme(color-primary);
                }
            }
        }
    }

    .product-icons {
        position: absolute;
        bottom: 0;
        text-align: right;

        &__item {
            display: inline-block;
            float: none;
            width: auto;
            margin: auto auto auto 5px;

            // hide label
            span {
                display: none;
            }
        }
    }

    .rx-wrapper {
        height: 0;
        padding-bottom: 100%;
        width: 100%;
        position: relative;

        @media #{$media-sm} {
            padding-bottom: 230px;
        }

        img {
            margin: 0 auto;
        }
    }

    .rx-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
