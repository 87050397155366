.category-box {
    $b: &;
    position: relative;

    .category-box__banner {
        text-transform: uppercase;
        color: black;
        position: relative;
        min-height: 35px;

        span {
            position: absolute;
            top: 50%;
            left: 50px;
            transform: translateY(-50%);
        }

        img {
            width: 100%;
        }
    }

    .product-list__items {
        &.category-box {
            margin-top: 30px;
            margin-bottom: 30px;

            @media #{$media-xs} {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .product-list__item {
        @media #{$media-xs} {
            margin-bottom: 10px;
        }
    }
    
    #{$b}__more {
        top: 3px;
        right: 0;
        color: $sherpa-blue;

        &:hover {
            color: #000;
        }

        svg {
            fill {
                color: $sherpa-blue;
            }
        }
    }
}
