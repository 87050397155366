.content-faq {
    $b: &;

    @media #{$media-to-sm} {
        margin-bottom: 30px;
    }

    @media #{$media-sm-up} {
        margin: 20px auto;
    }

    @media #{$media-xs} {
        width: 100%;
    }

    #{$b}__title {
        font-size: 25px;
        margin-bottom: 50px;

        @media #{$media-to-sm} {
            margin-bottom: 40px;
        }

        @media #{$media-xs} {
            font-size: 20px;
        }
    }

    &__item {
        border-radius: 8px;
        display: flex;
        align-items: stretch;
        margin-bottom: 10px;
        position: relative;

        &.is-active{
            #{$b}__number {
                background-color: $blue-dianne;
            }

            #{$b}__arrow {
                border-width: 2px 0 0 2px;
                transform: translateY(70%) rotate(45deg);
            }
        }

        &:focus {
            .content-faq__number {
                background-color: $blue-dianne;
            }
        }
    }

    &__number {
        display: flex;
        align-items: center;
        background-color: $sherpa-blue;
        color: #fff;
        font-size: 24px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 70px;
        min-height: 70px;
        height: 100%;
        font-weight: $font-weight-bold;
        padding: 0;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;

        @media #{$media-sm} {
            padding: 15px;
        }

        @media #{$media-xs} {
            width: 22px;
        }
    }

    &__header {
        padding: 23px 45px 15px 15px;
        min-height: 69px;
        font-weight: 600;

        @media #{$media-md-up} {
            font-size: 16px;
            padding: 23px 35px 15px;
        }

        h3 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0;
        }

        &.is-active {

            .content-faq__number {
                background-color: $dark-green;
                pointer-events: none;
            }

            .content-faq__arrow {
                border-width: 2px 0 0 2px;
                transform: translateY(70%) rotate(45deg);
            }


            &:focus {
                .content-faq__number {
                    background-color: $blue-dianne;
                }
            }
        }

        a {
            padding-left: 70px;
            display: inline-block;

            @media #{$media-sm} {
                padding-left: 80px;
            }

            @media #{$media-xs} {
                padding-left: 20px;
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                height: 70px;
            }

            &:focus {
                outline-color: transparent;
            }
        }
    }

    &__text {
        width: 100%;
        border: 1px solid $sherpa-blue;
        border-radius: 8px;

        p {
            margin-bottom: 0;
        }
    }

    &__answer {
        color: $blue-dianne;
        padding: 0 15px 20px;
        font-size: 14px;
        line-height: 18px;

        @media #{$media-md-up} {
            padding: 0 45px 40px 35px;
        }

        ul {
            margin-left: 30px;
            margin-top: 5px;

            li {
                list-style-type: disc;
            }
        }

        p {
            margin-top: 15px;
            line-height: 18px;
        }

        a {
            text-decoration: underline;
            font-weight: 600;
        }
    }

    &__arrow {
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 21px;
        right: 26px;
        transform: translateY(30%) rotate(45deg);
        border: 2px solid $sherpa-blue;
        border-left-width: 0;
        border-top-width: 0;

        @media #{$media-to-sm} {
            top: 19px;
        }

        @media #{$media-xs} {
            top: 23px;
            right: 24px;
            width: 12px;
            height: 12px;
        }
    }

    &__wrapper {
        padding-left: 70px;

        @media #{$media-sm} {
            padding-left: 80px;
        }

        @media #{$media-xs} {
            padding-left: 20px;
        }
    }
}

.article-page,
.text-page {

    .content-faq {

        &__title {
            @media #{$media-sm-up} {
                font-size: 21px;
            }
        }

        &__number {
            @media #{$media-md-up} {
                width: 55px;
            }
        }

        &__header {
            a {
                @media #{$media-md-up} {
                    padding-left: 55px;
                }
            }

            h3 {
                font-size: 20px;
            }
        }

        &__answer {
            @media #{$media-md-up} {
                padding: 0 45px 40px 20px;
            }
        }
    }
}

.product-view {
    .content-faq {
        @media #{$media-xs} {
            margin: 20px auto;
        }
    }
}

.catalog-category-view {
    .content-faq {
        &__title {
            @media #{$media-sm-up} {
                font-size: 21px;
                margin-bottom: 40px;
            }
        }
    }
}
