/* BAKCDROP LAYER
-------------------------------------------------------------- */
.backdrop {
    z-index: -1;

    &.in {
        z-index: $zindex-modal-background;
    }

    &--under {
        &:after {
            content: '';
            width: 100%;

            background-color: #f5f5f5;
            position: fixed;
            z-index: 0;
            opacity: 0;
            visibility: hidden;
            top: 0;
            left: 0;

            @include n-transition(opacity, 0.3s);
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1, 1);
        }

        /* show layer under element is focused or body has class dropdown-is-open */
        .dropdown-is-open,
        .backdrop-is-visible & {
            &:after {
                opacity: 0.85;
                visibility: visible;
                height: 100vh;
            }
        }
    }
}
