/* PRODUCT ITEM RX STYLES
-------------------------------------------------------------- */
.product-rx {
    /* text and icon */
    &__info {
        text-align: center;

        .product-rx--norefund & {
            @include n-center();
        }

        p {
            margin-bottom: 30px;
            line-height: 1.4;

            .product-rx--norefund & {
                font-size: 20px;
                color: palette(blue);

                @include n-theme() {
                    color: theme(color-primary);
                }
            }

            span {
                color: palette(red);
            }
        }

        .badge-icon {
            @include n-badge-size(92px, 50px);

            @media #{$media-md} {
                @include n-badge-size(82px, 40px);
            }

            &,
            &:hover {
                border-color: rgba(0, 0, 0, 0.1);
                background: none;
            }

            .product-rx--norefund & {
                margin-bottom: 20px;
            }

            i {
                color: rgba(0, 0, 0, 0.15);
            }
        }
    }
}

.tooltip-rx {
    margin: -10px 0;
}
