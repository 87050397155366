.form-search {
    // Style up the autocomplete to be just
    // like a TWBS list group.
    .search-autocomplete {
        z-index: $zindex-search;

        li {
            //@extend .list-group-item;
            cursor: pointer;

            .amount {
                float: right;
            }

            // Bootstrap uses a hidden list
            // item for the first child, so
            // we target the first child to
            // [class="first"].
            &.first {
                @include border-top-radius($list-group-border-radius);
            }

            // Hover state
            &:hover,
            &:focus {
                text-decoration: none;
                background-color: $list-group-hover-bg;
            }

            // When somebody selects an item
            &.selected,
            &.selected:hover,
            &.selected:focus {
                z-index: 2; // Place active items above their siblings for proper border styling
                color: $list-group-active-color;
                background-color: $list-group-active-bg;
                border-color: $list-group-active-border;
            }
        }
    }
}
