/* MINI CART
-------------------------------------------------------------- */
.minicart {
    position: relative;

    /* item counter */
    &__counter {
        display: inline-block;
       
        @media #{$media-xs} {
            margin-right: 0;
        }

        i {
            font-size: 37px;
        }

        svg {
            color: $sherpa-blue;
        }

        .cart-count {
            position: absolute;
            top: 2px;
            right: 1px;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            text-align: center;
            color: #fff;
            line-height: 21px;
            
            @include n-theme() {
                background-color: theme(color-primary);
            }

            @media #{$media-xs} {
                width: 15px;
                height: 15px;
                top: -1px;
                right: -2px;
                font-size: 10px;
                font-size: 11px;
                line-height: 15px;
            }
        }

        & + span {
            color: palette(blue);
            display: inline-block;
            vertical-align: middle;

            @include n-theme() {
                color: theme(color-primary);
            }
        }
    }
}
