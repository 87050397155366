/* PAGINATION
-------------------------------------------------------------- */
$pagination-border-radius: 8px;
$pagination-color: $sherpa-blue;

.pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    > li {
        display: inline-block;
        font-size: 16px;

        > a,
        > span {
            border-color: transparent;
            border-radius: $pagination-border-radius;
            padding: 5px;
            height: 35px;
            width: 35px;
            min-height: 35px;
            min-width: 35px;
            margin-left: 8px;
            margin-right: 8px;
            text-align: center;
            font-weight: 600;
            box-shadow: 0 2px 8px rgba(183, 189, 196, 0.503551);

            .icon {
                position: relative;
                top: -2px;
                margin-left: -1px;
                margin-right: -1px;
                font-size: 13px;
            }

            &.pagination-arrow {
                box-shadow: none;
                margin-left: 0px;
                margin-right: 0px;

                &:hover,
                &:focus {
                    background: none;
                    border-color: white;
                }
            }
        }

        &:first-child {
            > a,
            > span {
                border-top-left-radius: $pagination-border-radius;
                border-bottom-left-radius: $pagination-border-radius;
            }
        }

        &:last-child {
            > a,
            > span {
                border-top-right-radius: $pagination-border-radius;
                border-bottom-right-radius: $pagination-border-radius;
            }
        }
    }

    > .active {
        > a,
        > a:hover,
        > a:focus,
        > span,
        > span:hover,
        > span:focus {
            background-color: $pagination-color;
            border-color: $pagination-color;
        }
    }
}
