/* MODALS OVERRIDE BOOTSTRAP SETTINGS
-------------------------------------------------------------- */
.modal {
    padding: 0;
    text-align: center;
    -webkit-overflow-scrolling: auto;

    /* centering */
    &:before {
        @media #{$media-sm-up} {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }
    }

    &-dialog {
        display: inline-block;
        vertical-align: middle;
        background: #fff;
        border-radius: 12px;
        /*text-align: left;*/

        @media #{$media-xs} {
            margin: 60px 10px 50px 10px;
        }

        /* styles for ng-dialog plugin */
        .ngdialog & {
            margin: 0;
            overflow: hidden;
            z-index: 0;
        }

        &:before,
        &:after {
            content: '';
            @include n-logotype-bg($size: 300px);
            background-size: contain;
            border-radius: 12px;
            z-index: -1;
        }

        &:before {
            left: 0;
            top: 0;
            background-position: -150px -150px;
        }

        &:after {
            right: 0;
            bottom: 0;
            background-position: 150px 150px;
        }
    }

    &-content {
        box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
        padding: 45px;
        min-height: 155px;
        border: 0;
        text-align: left;

        @media #{$media-xs} {
            padding: 25px;
        }
    }

    &-header,
    &-body {
        padding: 0;
    }

    &-header {
        border: 0;
    }

    &.show {
        opacity: 1;

        .modal-dialog {
            -webkit-transform: translate(0);
            -ms-transform: translate(0);
            -o-transform: translate(0);
            transform: translate(0);
        }
    }

    &-backdrop {
        background-color: rgba(0, 0, 0, 0.4);

        &.in,
        &.show {
            opacity: 0.8;
        }
    }

    .btn-close {
        position: absolute;
        top: -22px;
        right: -22px;

        @media #{$media-xs} {
            right: -7px;
        }
    }
}

/* NOTIFY MODAL
-------------------------------------------------------------- */
.modal-notify {
    .modal-header {
        text-align: center;

        h3 {
            margin: 0;
        }
    }
}

/* PERMANENT ORDER MODAL
-------------------------------------------------------------- */
.modal-permanent-order {
    .modal-dialog {
        &:before,
        &:after {
            @media #{$media-xs} {
                width: 250px;
                height: 250px;
            }
        }

        &:after {
            @media #{$media-xs} {
                background-position: 150px 150px;
            }
        }
    }

    .modal-content {
        padding: 75px;

        @media #{$media-sm} {
            padding-top: 60px;
            padding-bototm: 70px;
        }

        @media #{$media-xs} {
            padding: 35px 25px 25px;
        }
    }

    .modal-header {
        text-align: center;
        margin-bottom: 35px;

        @media #{$media-xs} {
            margin-bottom: 23px;
        }
    }

    .modal-title {
        @media #{$media-xs} {
            font-size: 15px;
        }
    }

    .modal-body {
        text-align: center;

        .input-group {
            &.new-permanent {
                margin-bottom: 45px;

                @media #{$media-xs} {
                    width: 100%;
                    margin-bottom: 65px;
                }

                input {
                    @media #{$media-xs} {
                        margin-bottom: 10px;
                    }
                }

                .input-group-btn {
                    @media #{$media-xs} {
                        display: block;
                        width: 100%;
                    }

                    .btn {
                        border-bottom-left-radius: 12px;
                        border-top-left-radius: 12px;
                        margin-left: 20px;

                        @media #{$media-xs} {
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }

        p {
            font-weight: 600;
        }
    }

    .info-empty-cart {
        display: none;
        margin-top: 15px;

        @media #{$media-xs} {
            margin-top: 23px;
            text-align: left;
        }

        span {
            opacity: 0.5;
            margin-right: 10px;

            @media #{$media-xs} {
                display: block;
                float: left;
                margin-right: 16px;
            }
        }

        p {
            display: inline-block;
            font-style: italic;
            font-weight: normal;
            font-size: 14px;

            @media #{$media-xs} {
                display: block;
            }
        }
    }

    .customer-orders {
        display: none;
        margin-top: 25px;
        max-height: 190px;
        overflow: auto;

        @media #{$media-xs} {
            margin-top: 30px;
            margin-bottom: 15px;
            max-height: 245px;
        }

        ul {
            border-top: 1px solid $gray-lighter;

            li {
                padding-left: 10px;
                padding-right: 10px;
                border-bottom: 1px solid $gray-lighter;
                overflow: hidden;

                @media #{$media-xs} {
                    padding-top: 15px;
                    padding-bottom: 5px;
                }

                .input-group {
                    min-height: 60px;
                    margin-bottom: 0;

                    @media #{$media-xs} {
                        width: 100%;
                        padding-bottom: 11px;
                    }

                    .input-group-btn {
                        @media #{$media-xs} {
                            display: block;
                            width: 100%;
                        }

                        button {
                            @media #{$media-xs} {
                                font-size: 14px;
                                min-height: 25px;
                            }
                        }
                    }
                }

                .order-name {
                    position: relative;
                    display: table-cell;
                    text-align: left;
                    font-size: 17px;
                    font-weight: 600;
                    vertical-align: middle;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    max-width: 100px;
                    overflow: hidden;
                    word-break: break-word;
                    padding-right: 15px;

                    @media #{$media-xs} {
                        display: block;
                        max-width: 100%;
                        text-align: center;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }

        &.ps-active-y {
            ul li {
                padding-right: 15px;
            }
        }
    }

    .modal-loader {
        display: none;

        .loader {
            display: block;
            width: 30px;
            height: 30px;
            margin: auto;
            border-width: 3px;
            border-left-color: palette(blue);

            @include n-theme() {
                border-left-color: theme(color-primary);
            }
        }
    }

    .message {
        margin-top: -40px;
        margin-bottom: 19px;
        color: $green;

        &--error {
            color: $red;
        }
    }
}


.catalog-product-view {
    .modal-dialog {
       

        &:before,
        &:after {
            display: none;
        }
    }

    .review-modal {
        .h2 {
            font-size: 26px;
        }

        p {
            font-size: 20px;
        }
    }
}