/* COLOR VARIANT MIXIN
-------------------------------------------------------------- */
@mixin color-variant($color) {
    @include n-color-variant($color);
}

/* COLORS
-------------------------------------------------------------- */
.color-box {
    border-bottom-color: $sherpa-blue!important;
}

.color {
    &--blue {
        @include color-variant(palette(blue));
    }

    &--yellow {
        @include color-variant(palette(yellow));
    }

    &--orange {
        @include color-variant(palette(orange));
    }

    &--green {
        @include color-variant(palette(green));
    }

    &--red {
        @include color-variant(palette(red));
    }

    &--blue-light {
        @include color-variant(#3498db);
    }

    &--purple {
        @include color-variant(#9b59b6);
    }

    &--brown {
        @include color-variant(#d35400);
    }

    &--gray {
        @include color-variant(#7d8c8f);
    }
}
