.faq {
    margin-bottom: 100px;

    &__category {
        margin-top: 50px;
        margin-bottom: 30px;
    }

    &-items {
        box-shadow: 0 2px 15px rgba(25, 25, 25, 0.06);
    }

    &-item {
        display: block;
        border-radius: 0;
        text-align: left;

        &__question {
            a {
                @media #{$media-xs} {
                    padding: 15px 15px;
                }

                &[aria-expanded='true'] {
                    background-color: $blue;
                    color: white;

                    @include n-theme() {
                        background-color: theme(color-primary);
                    }

                    .arrow {
                        transform: rotate(180deg);
                        top: 20px;
                    }
                }

                .arrow {
                    transform: none;
                    bottom: auto;
                    top: 15px;
                }
            }
        }

        &__answer {
            background-color: white;
            padding: 40px 25px;

            @media #{$media-xs} {
                padding: 20px 15px;
            }

            p {
                margin: 0;
                font-weight: normal;
            }
        }

        &__arrow {
            position: absolute;
            width: 20px;
            top: 20px;
        
            &:after {
                content: "";
                display: block;
                height: 13px;
                width: 13px;
                bottom: 50%;
                position: absolute;
                transform: translateY(30%) rotate(45deg);
                border: 2px solid #000;
                border-left-width: 0;
                border-top-width: 0;
            }
        }
    }

    
    
    .faq-item__question a .arrow {
        position: absolute;
        width: 20px;
        top: 20px;
    }
    
    .faq-item__question a[aria-expanded='true'] .arrow {
        right: 27px;
        &:after {
            border-color: #fff;
        }
    }        
}
