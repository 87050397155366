/* PRODUCT VIEW
-------------------------------------------------------------- */
.price {
    font-family: $font-family-secondary;
    white-space: nowrap;
    color: palette(green);

    @include n-theme() {
        color: theme(color-secondary);
    }
}

.price-box {
    &--promo {
        .price {
            color: palette(red);
        }
    }

    .price-label {
        white-space: nowrap;
    }
}

// Regular price
.regular-price {
}

// Old price
.old-price {
    margin: 0;
    color: $text-color;
    font-size: 16px;
    line-height: 20px;

    .price {
        color: inherit;
        font-weight: normal;
        text-decoration: line-through;
    }
}

// Special price
.special-price {
    margin: 0;

    .price-label {
        font-weight: $font-weight-bold;
        color: $brand-primary;
    }
}

// Minimal price (as low as)
.minimal-price {
    margin: 0;

    .price-label {
        font-weight: $font-weight-bold;
    }
}

.minimal-price-link {
    display: block;

    .price {
        font-weight: normal;
    }
}

.new-price {
    color: $red;
}
