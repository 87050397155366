/* HTML BLOCKS
-------------------------------------------------------------- */
.block {
    margin-bottom: 100px;

    &-title {
        padding: 0 0 15px 0;
        border-bottom: 1px solid $gray-lighter;
        font-size: 20px;
        font-weight: 700;

        a {
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
}
