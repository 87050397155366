.newsletter-box {
    position: relative;
    padding: 15px 24px;
    height: 100%;
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
    background-image: linear-gradient(
            140deg,
            rgba(58, 155, 220, 0.1) 0%,
            rgba(255, 255, 255, 0.1) 80%,
            rgba(255, 255, 255, 0.1) 100%
    );
    overflow: hidden;

    @media #{$media-to-md} {
        max-height: 300px;
    }

    &:before,
    &:after {
        @include n-logotype-bg($size: 120px);
        content: '';
        pointer-events: none;
    }

    &:before {
        left: -60px;
        top: -60px;
    }

    &:after {
        right: -60px;
        bottom: -60px;
    }

    &__icon {
        width: 100%;
        font-size: 32px;
        text-align: center;
        color: $blue-light;
        margin-bottom: 13px;

        //&:before {
        //  @include icon();
        //  @extend .icon-mail:before;
        //  position: absolute;
        //  left: -7px;
        //  bottom: -15px;
        //  font-size: 44px;
        //  font-weight: bold;
        //  opacity: 0.1;
        //  @include transform(rotate(-30deg));
        //
        //  @media #{$media-xs} {
        //    display: none;
        //  }
        //}
    }

    &__title {
        font-size: 21px;
        text-align: center;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 2px;
    }

    &__sub-title {
        display: block;
        margin-bottom: 12px;
        font-size: 21px;
        font-weight: lighter;
    }

    &__text {
        font-size: 15px;
        text-align: center;

        b {
            font-size: 17px;
            font-weight: 700;
            color: $blue-light;
        }

        ul {
            font-size: 14px;
            text-align: left;

            @media #{$media-to-md} {
                margin-bottom: 20px;
            }

            li:before {
                background-color: $blue-light;
            }

            & + p {
                text-align: left;
            }
        }
    }

    &__submit {
        width: 100%;
        color: #fff !important;
        border-radius: 24px;
        background-color: $sherpa-blue;
        padding: 0 20px;
        border: none;

        .icon-paper-plane {
            width: 24px;
            height: 24px;
            filter: $svg-white;
        }

        &:hover,
        &:active {
            background-color: $dark-green !important;
            background: $dark-green !important;
        }
    }
}
