/* TEXT-TRUNCATE
-------------------------------------------------------------- */
.text-truncate {
    overflow: hidden;

    &.is-expanded {
        max-height: none !important;
        height: auto;
        overflow: visible;
    }

    & + &__more {
        display: none;

        i {
            position: relative;
            top: -1px;
            font-size: 8px;
        }

        .less {
            display: none;
        }
    }

    &.is-expanded + &__more {
        display: block;

        .more {
            display: none;
        }

        .less {
            display: inline-block;
        }
    }

    &.is-truncated + &__more {
        display: block;
    }
}
