/* PRODUCT LIST
-------------------------------------------------------------- */
a.product-list__link {
    &:hover {
        color: $dark-green;
    }
}

.product-list {
    margin-top: 80px;
    position: relative;

    &__list {
        .icon-wrapper-grid {
            display: none;
        }

        .icon-wrapper-list {
            display: flex;
        }
    }

    &__grid {
        .icon-wrapper-grid {
            z-index: 2;
            right: 5px;
            display: flex;
        }

        .icon-wrapper-list {
            display: none;
        }
    }

    &__title {
        display: table;
        max-width: 500px;
        margin: 0 auto;

        @media #{$media-to-sm} {
            margin-bottom: 30px;
        }

        @media #{$media-xs} {
            max-width: 310px;
            margin-bottom: 20px;
        }

        .page-subtitle {
            padding-bottom: 0;

            h2 {
                font-size: 18px;
                font-weight: 600;
                line-height: 29px;

                @media #{$media-sm-up} {
                    font-size: 28px;
                }
            }
        }
    }

    &__wrapper {
        position: relative;
        margin-top: 20px;

        @media #{$media-xs} {
            max-width: 450px;
            margin: 0 auto;
        }
    }

    &__item {
        min-width: 184px;
        margin-bottom: 43px;

        @media #{$media-xs} {
            margin-bottom: 20px;
        }

        @media screen and (max-width: 430px) {
            margin-bottom: 7px;
        }

        @media screen and (max-width: 370px) {
            margin-bottom: 20px;
        }

        .prod-box__img {
            padding-top: 60px;
            min-height: 130px;
            border-bottom: none;

            img {
                max-width: 130px !important;
                max-height: 130px !important;
            }
        }

        .prod-box__title {
            font-size: 14px;
            font-weight: 600;
            line-height: 15.4px;
            padding-top: 4px;
            height: 50px;
            padding-left: 7px;
        }

        .prod-box form {
            padding: 0 5px;
            padding-top: 5px;
            padding-left: 7px;
            padding-right: 3px;
        }

        .prod-box__btn {
            padding-right: 58px;
            padding-top: 15px;
            padding-left: 16px;

            &:after {
                right: 36px;

                @media screen and (max-width: 370px) {
                    right: 20px;
                }
            }

            &:hover {
                .price {
                    color: $brand-primary-text;
                }
            }

            .price {
                font-size: 18px;
                font-weight: 600;
                color: $blue-dianne;
            }
        }

        .tooltip-max-price-info {
            padding: 0px;
            margin: 8px 0 0;

            p {
                font-size: 8px;
                font-weight: 400;
                line-height: 10px;

                @media #{$media-xs} {
                    font-size: 7px;
                }

                & > span {
                    line-height: 23px;
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;

        .col-xs-6,
        .col-sm-6,
        .col-md-3 {
            width: 100% !important;
        }

        .product-item {
            max-width: initial;
            min-width: initial;
            width: 100%;
            flex-direction: row;

            .mobile-column-for-row-view {
                display: flex;
                flex-direction: row;
                max-width: 1014px;
                width: inherit;

                @media #{$media-xs} {
                    flex-direction: column;
                    margin-bottom: 30px;
                }
            }

            .mobile-column {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            /* PRODUCT IMAGE
            -------------------------------------------------------------- */
            .product-item__img {
                width: 100px;
                min-width: 152px;
                padding: 22px 30px 22px 22px;
                margin: 0;

                @media #{$media-to-xl} {
                    width: 100%;
                    flex: 0.15;
                }

                @media #{$media-xs} {
                    justify-content: flex-start;
                }

                @media screen and (max-width: 410px) {
                    min-width: 108px;
                    padding: 20px 16px 22px 16px;
                    margin: 0;
                }
            }

            /* DESCRIPTION BOX
            -------------------------------------------------------------- */
            &__desc {
                width: max-content;
                max-width: 600px;
                height: max-content;

                @media #{$media-to-xl} {
                    width: 100%;
                    flex: 0.45;
                }
            }

            /* PRODUCT NAME
            -------------------------------------------------------------- */
            &__name {
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                text-align: left;
                width: 600px;

                @media #{$media-to-xl} {
                    width: 100%;
                }
            }

            &__tags {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                text-align: left;
            }

            &__labels {
                top: 5px;

                .product-label {
                    @media #{$media-to-md} {
                    }

                    &--stp {
                        background-color: $product-label-stp;
                    }

                    &--popular {
                        background-color: $product-label-popular;
                    }

                    &--grouped {
                        background-color: $product-label-grouped;
                    }

                    &--last_items {
                        background-color: $product-label-last-items;
                    }
                }
            }

            /* PRODUCT PRICE
            -------------------------------------------------------------- */
            .price-box,
            .price {
                font-size: 18px;
                font-weight: 600;
                line-height: 1;

                @media screen and (max-width: 440px) {
                    font-size: 18px;
                }
            }

            .price {
                text-transform: none;
            }

            .special-price,
            .old-price {
                display: inline-block;
            }

            /* ADD TO CART
            -------------------------------------------------------------- */
            @media #{$media-xs} {
                .icon-wrapper-grid {
                    display: flex;
                }

                .icon-wrapper-list {
                    display: none;
                }
            }

            &__shop {
                display: flex;
                position: relative;
                flex-direction: row-reverse;
                margin-top: 0;

                @media #{$media-to-xl} {
                    flex: 0.4;
                    width: 100%;
                }

                @media #{$media-xs} {
                    margin-top: 8px;
                    justify-content: flex-end;
                }

                form {
                    display: flex;
                    flex-direction: row;

                    @media #{$media-xs} {
                        flex-direction: column;
                    }
                }

                .tooltip-max-price-info {
                    position: absolute;
                    top: 26px;
                    right: 60px;

                    p {
                        font-size: 7px;
                        font-weight: 400;
                        line-height: 12px;
                        text-align: left;
                        text-decoration: none;
                    }
                    @media #{$media-xs} {
                        top: 52px;
                        left: 0;
                    }
                    @media #{$media-xl} {
                        right: 68px;
                    }
                }
            }

            &__btn {
                height: 32px;
                width: 196px;
                margin: 0 28px 0 75px;
                border: 1px solid $sherpa-blue;
                padding: inherit;

                @media #{$media-to-xl} {
                    margin: 0 20px;
                }

                @media #{$media-xs} {
                    margin: 0;
                    padding-left: 45px;
                }

                @include n-theme() {
                }

                .add-to-cart {
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 12px;
                }

                &:hover,
                &:active,
                &:focus {
                    &,
                    .price {
                        color: white !important;
                    }
                }
            }

            /* RX PRODUCTS
            -------------------------------------------------------------- */
            &__rx {
                p {
                    @include n-center(absolute);
                    @media #{$media-md} {
                    }

                    .product-item--unavailable & {
                    }
                }

                strong {
                    @include n-theme() {
                    }
                }

                .badge-icon {
                    @media #{$media-md} {
                    }

                    .product-item--unavailable & {
                    }
                }
            }
        }
    }

    &__link {
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $sherpa-blue;
        display: block;

        @media #{$media-to-sm} {
            position: static;
            text-align: center;
            width: 100%;
            margin-bottom: 35px;
        }

        .icons {
            font-size: 8px;
            vertical-align: middle;
            margin-left: 2px;
            white-space: nowrap;

            i {
                &:first-of-type {
                    margin-right: -2px;
                }
            }
        }
    }

    &__items {
        margin-bottom: 18px;

        @media #{$media-xs} {
            margin-bottom: 0;
        }

        @media screen and (max-width: 430px) {
            margin-bottom: 20px;
        }

        @media screen and (max-width: 370px) {
            margin-bottom: 0;
        }
    }

    .product-item {
        &--unavailable {
            button {
                min-height: 52px;
                width: 100%;
                font-size: 13px;

                @media only screen and (min-width: 371px) and (max-width: 450px) {
                    min-width: 130px;
                    max-width: 160px;
                    white-space: normal;
                    max-height: 52px;
                    line-height: 16px;

                    &.link-wishlist {
                        min-width: auto;
                    }
                }
            }
        }

        &--refunded,
        &--unavailable {
            .product-item__shop {
                @media only screen and (min-width: 371px) and (max-width: 430px) {
                    padding: 0;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &--refunded {
            .btn {
                @media only screen and (min-width: 371px) and (max-width: 430px) {
                    max-width: 160px;
                    font-size: 15px;
                }
            }
        }
    }
}
