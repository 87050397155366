/* PRODUCT COLLECTION LISTS
-------------------------------------------------------------- */
.product-collection {
    $b: &;

    &__header {
        padding: 20px 0;
        text-align: center;
    }

    &__title {
        margin: 0;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
    }

    &__slider {
        position: relative;
        padding: 0 25px;
    }

    .slick-track {
        display: flex;
    }

    &__btn {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 100;

        .icon {
            z-index: 9;
            position: relative;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60px;
            height: 40px;
            margin-left: -30px;
            margin-top: -20px;
            background-color: $white;
            border: 1px solid $sherpa-blue;
            border-radius: 20px;

            @media #{$media-xs} {
                width: 50px;
                height: 34px;
                margin-left: -25px;
                margin-top: -17px;
            }
        }

        @include n-theme() {
            &:hover,
            &:active,
            &:focus {
                background: transparent;
            }
        }

        &--left {
            left: -36px;
        }

        &--right {
            right: -36px;
        }

        &:hover {
            &:after {
                border: 1px solid $dark-green;
            }

            svg {
                path {
                    stroke: $dark-green;
                }
            }
        }
    }

    &__item {
        #{$b}--wide & {
            @media #{$media-md-up} {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
}
