/* BADGE MIXINS
-------------------------------------------------------------- */
@mixin badge-size($size, $font-size) {
    @include n-badge-size($size, $font-size);
}

@mixin badge-color($bg, $border: $bg, $hover: $bg, $color: null) {
    @include n-badge-color($bg, $border, $hover, $color);
}

/* PRODUCT ICON
-------------------------------------------------------------- */
.badge-icon {
    position: relative;
    display: inline-grid;
    place-items: center;
    width: 65px;
    height: 65px;
    border: 2px solid palette(blue);
    background: palette(blue);
    border-radius: 100%;
    text-align: center;
    @include n-hover(blue);
    @include n-transition();
    @include n-theme() {
        background-color: theme(color-primary);
        border-color: theme(color-primary);
    }

    &:hover {
        border-color: palette(blue);
        @include n-theme() {
            background-color: $dark-green;
            border-color: $dark-green;
        }
    }

    /* mini version */
    &--mini {
        @include badge-size(25px, 15px);
    }

    &--xs {
        @include badge-size(30px, 12px);
    }

    /* small version */
    &--small {
        @include badge-size(40px, 22px);
    }

    /* medium version */
    &--medium {
        @include badge-size(65px, 36px);
    }

    /* medium version with small font*/
    &--md {
        @include badge-size(65px, 25px);
    }

    /* big version */
    &--big {
        width: 100px;
        height: 100px;

        i {
            font-size: 48px;
            line-height: 100px;
        }
    }

    &--large {
        width: 125px;
        height: 125px;
    }

    &--huge {
        width: 100px;
        height: 100px;

        i {
            font-size: 80px;
            line-height: 100px;
        }
    }

    &--transparent {
        background-color: transparent !important;
        border: 0;

        i {
            @include n-theme() {
                color: theme(color-primary);
            }
        }
    }

    &--rotate {
        transform: rotate(20deg);
    }

    /* inverted colors */
    &--inverted {
        background: #fff;
        border: 2px solid palette(blue);

        @include n-theme() {
            border-color: theme(color-primary);
        }

        i {
            color: palette(blue);

            @include n-theme() {
                color: #fff;
            }
        }

        &:hover {
            background: palette(blue);

            @include n-theme() {
                background: theme(color-primary);
            }

            i {
                color: #fff;
            }
        }
    }

    /* green inverted version */

    &--green {
        background: rgba(0, 0, 0, 0);
        border-color: #007d00;
        color: #007d00;

        i {
            color: #007d00;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            border-color: #007d00;

            i {
                color: #007d00;
            }
        }
    }

    /* gray version */
    &--gray {
        background: #646464;
        border-color: #646464;
        color: #fff;
    }

    /* light-gray version */
    &--light-gray {
        background: #fff;
        border: 3px solid $blue;
        opacity: 0.1;

        i {
            color: $blue;
        }
    }

    /* red version */
    &--red {
        @include n-hover(red);
        border-color: palette(red);
        color: #fff;

        &:hover {
            border-color: palette(red);
        }
    }

    /* blue light */
    &--blue-light {
        @include badge-color(
            #fff,
            palette(blue, light),
            palette(blue, light),
            palette(blue, light)
        );
    }

    /* muted version */
    &--muted {
        &,
        &:hover {
            background: transparent;
            border-color: rgba(palette(gray, dark), 0.1);
        }

        i {
            color: rgba(palette(gray, dark), 0.1);
        }
    }
}

/*

}

/* BADGE LABEL
-------------------------------------------------------------- */
.badge-label {
    position: relative;
    display: inline-block;
    padding: 6px 15px 6px 21px;
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    text-transform: uppercase;
    color: #fff;
    background: palette(red);
    border-top-left-radius: $border-radius-large;
    border-bottom-left-radius: $border-radius-large;

    /* css triangle with gradient */
    &:before,
    &:after {
        position: absolute;
        right: 0;
        top: 100%;
        content: '';
        width: 10px;
        height: 10px;
    }

    &:before {
        background-image: linear-gradient(to top, #ea1e4f 0%, #7d1e28 100%);
    }

    /* mask */
    &:after {
        background-color: #fff;
        @include rotate(45deg);
        height: 16px;
        right: -4px;
    }

    &--blue {
        background: $blue;
        left: 2px;
        border-radius: 0 12px 12px 0;

        &:before {
            left: 0;
            right: auto;
            background: url(../images/content/product/badge-label-triangle.png)
                bottom no-repeat;
            width: 10px;
            height: 12px;
            top: auto;
            bottom: -12px;
        }

        &:after {
            display: none;
        }
    }
}

/* BADGE TAG
-------------------------------------------------------------- */
.badge-tag {
    display: inline-block;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    background: palette(green);
    color: #fff;
}
