/* TABS SWITCH
-------------------------------------------------------------- */
.tab-quick {
    $b: &;
    display: table;
    height: 100%;

    &--switch {
        #{$b}__item {
            z-index: 0;
            height: 38px;

            &:not(:last-of-type) {
                @media #{$media-to-md} {
                    display: none;
                }
            }

            &:focus,
            &:active,
            &.is-open,
            &.is-active {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: -2px;
                    border-top-left-radius: $border-radius-small;
                    border-top-right-radius: $border-radius-small;
                    z-index: -1;
                }

                &:before {
                    bottom: -1px;
                    width: 100%;
                    overflow: hidden;
                    background: #fff;
                    border: 1px solid palette(gray, lighter4);
                    border-bottom: none;
                }

                &:after {
                    left: 1px;
                    right: 1px;
                    border-top: 4px solid palette(blue);
                }

                #{$b}__link {
                    color: palette(gray);
                }
            }

            &--kardio {
                &:active,
                &.is-open,
                &.is-active,
                &:focus {
                    &:after {
                        border-top-color: palette(general);
                    }
                }
            }

            &--general {
                &:active,
                &.is-open,
                &.is-active,
                &:focus {
                    &:after {
                        border-top-color: palette(general);
                    }
                }
            }

            &--alergie {
                &:active,
                &.is-open,
                &.is-active,
                &:focus {
                    &:after {
                        border-top-color: palette(general);
                    }
                }
            }
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: 38px;

        &--categories {
            #{$b}__link {
                cursor: pointer;
                color: palette(blue);

                @include n-theme() {
                    color: theme(color-primary);
                }
            }

            &:after {
                display: none;
            }

            .triangle {
                display: inline-block;
                margin: -2px 0 0 10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                border-color: #000000 transparent transparent transparent;
                vertical-align: inherit;
            }

            &.is-open {
                .triangle {
                    border-width: 0 4px 5px 4px;
                    border-color: transparent transparent #000 transparent;
                }
            }
        }
    }

    // avoid padding on first and last
    // only if tab-quick is not --switch
    &:not(#{$b}--switch) {
        #{$b}__item {
            &:first-of-type {
                #{$b}__link {
                    padding-left: 0;
                }
            }

            &:last-of-type {
                #{$b}__link {
                    padding-right: 0;
                }
            }
        }

        #{$b}__link {
            @media #{$media-sm} {
                padding-right: 5px;
            }
        }
    }

    &__link {
        display: block;
        height: 38px;
        padding: 8px 15px;

        &:focus:active {
            outline: 0;
        }

        @media #{$media-to-sm} {
            padding-left: 10px;
            padding-right: 10px;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }

        .icon {
            margin-right: 7px;

            & + span {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}
