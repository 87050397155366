/* COOKIES
-------------------------------------------------------------- */

.notice-cookie {
    position: fixed;
    display: none;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30000000; /* make sure it is above livezilla chat */
    background-color: white;
    box-shadow: 0 0 12px rgba(25, 25, 25, 0.2);

    .container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__text {
        position: relative;

        @media #{$media-md-up} {
            padding-right: 290px;
            min-height: 50px;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 15px;
        line-height: 1.4em;
        font-size: 13px;
        text-align: justify;

        @media #{$media-md-up} {
            margin-bottom: 0;
            line-height: 1.2em;
            font-size: 14px;
            text-align: left;
        }

        + p {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    button {
        display: block;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        font-weight: 500;

        @media #{$media-md-up} {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            width: 235px;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
